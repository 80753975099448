
import { ElForm } from 'node_modules/element-ui/types/form';
import { Component, Vue, Prop, Watch, Emit } from 'vue-property-decorator';
import { verifyPhone } from '@/utils/utils';

@Component({
  name: 'UnboundDialog',
})
export default class UnboundDialog extends Vue {
    private unboundData: any = {
        unbindReason: '',
    };
    private unboundRules: any = {
        unbindReason: [
            { required: true, message: '请输入解绑原因', trigger: 'change' },
        ],
    };
    @Prop({
        type: Boolean,
        default: false,
    }) private EditFlag!: boolean; // 接收父组件传过来的值
    @Prop({
        type: Number,
        default: 0,
    }) private customerId !: number;
    private showDialog: boolean = false; // 弹窗显示与关闭
    @Watch('EditFlag') // 监听父组件传过来的值
    private EditFlagFun(value: boolean) {
        this.showDialog = value;
    }
    // 右上角的关闭按钮
    private beforeClose() {
        this.closeHandleTodo(1); // 给父组件传值
    }
    // 给父组件传值
    @Emit('closeHandle')
    private closeHandleTodo(num: number): number {
        this.showDialog = false;
        this.unboundData = {
            unbindReason: '',
        };
        this.resetForm('unboundForm');
        return num;
    }
    private resetForm(formName: any) {
        const elForm = this.$refs[formName]! as ElForm;
        elForm.resetFields();
    }
    private submitUnbound(formName: any) {
        const elForm = this.$refs[formName]! as ElForm;
        elForm.validate((valid: boolean) => {
            if (valid) {
                this.unboundRequest();
            }
        });
    }
    private unboundRequest() {
        const paramData: any = {};
        paramData.unbindReason = this.unboundData.unbindReason;
        this.$httpService
        .patchData(paramData, `/apiProxy/api/frontend/company/customer/${this.customerId}/status/UNBOUND`)
        .then((res: any) => {
            this.$message.success('解绑成功');
            this.closeHandleTodo(2); // 给父组件传值
        });
    }
}
