
import { Component, Vue, Prop, Watch, Emit } from 'vue-property-decorator';
import FollowDialog from "./followDialog.vue";

@Component({
    name: 'CustomerDetail',
    components: {
        FollowDialog,
    },
})
export default class CustomerDetail extends Vue {
    @Prop({
        type: Number,
        default: 0,
    }) private customerId !: number;
    private followRecordList: any = [];
    private customerTab: any = {};
    private followDialog: boolean = false;
    private created() {
        if (this.customerId !== 0) {
            this.getCustomerDetail();
        }
    }
    private getCustomerDetail() {
        this.$httpService.getData({}, `/apiProxy/api/frontend/company/customer/${this.customerId}`)
        .then((res: any) => {
            this.followRecordList = res.followRecordList;
            this.customerTab = res;
        });
    }
    // 给父组件传值
    @Emit('backHandle')
    private backHandleTodo() {
        return false;
    }
    private goBackHandle() {
       this.backHandleTodo();
    }
    // 给父组件传值
    @Emit('againHandle')
    private againHandleTodo() {
        return true;
    }
    private goAgainHandle() {
        this.againHandleTodo();
    }
    private checkAuthInfo() {
        // v2/user/company/customer/editCompanyInfo.htm?companyId=49987
        this.$router.push(`/company/redirectPage?url=/v2/user/company/customer/editCompanyInfo.htm&companyId=${this.customerTab.customerCompanyId}`)
        .catch((err) => err);
    }
    private followClick() {
        this.followDialog = true;
    }
    private followClose(num: number) {
        if (num === 2) {
            this.getCustomerDetail();
        }
        this.followDialog = false;
    }
}
