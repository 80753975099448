
import { ElForm } from 'element-ui/types/form';
import { Component, Emit, Prop, Vue, Watch } from 'vue-property-decorator';
import { verifyPhone } from '@/utils/utils';
import SearchCrm from "@/components/SearchCrm.vue";

@Component({
    name: "AddCustomer",
    components: {
        SearchCrm,
    },
})
export default class AddCustomer extends Vue {
    @Prop({
        type: Number,
        default: 0,
    }) private customerId !: number;
    private contentTitle: string = "添加报备客户";
    private activeIndex: number = 1; // 1、填写基础信息；2、填写企业详情；
    private activePage: number = 1; // 1、添加报备客户；2、特殊企业报备；
    private dropdownFlag: boolean = false;
    private prevCustomerName: string = '';
    private customerData: any = {
        name: '',
        taxpayerId: '',
        contacts: '',
        mobile: '',
    };
    private addCustomerData: any = {
        // contacts: '',
        // infoPost: '',
        // address: '',
        // industryTypeId: '',
        // mainBusiness: '',
        // informatizationLevel: '',
        // productionLine: '',
        // storehouseNumber: '',
        // projectDesc: '',
        infoPost: '',
        crmGender: '',
        crmIndustryId: '',
        crmMainCustomer: '',
        mainBusiness: '',
        crmInformatizationLevel: [],
        crmEnterpriseNature: '',
        crmPvSize: '',
        crmStaffSize: '',
        crmFactoryNum: '',
        crmFactoryAddress: '',
    };
    private formRules: any = {
        name: [
            { required: true, validator: this.validateName, trigger: 'change' },
        ],
        taxpayerId: [
            { required: true, message: '您当前客户企业名称未找到匹配的纳税人识别号，请输入正确的纳税人识别号!', trigger: 'change' },
        ],
        mobile: [
            { required: true, message: '请输入企业联系人手机号', trigger: 'change' },
        ],
        contacts: [
            { required: true, message: '请输入企业联系人', trigger: 'change' },
        ],
    };
    private customerRules: any = {
        // contacts: [
        //     { required: true, message: '请输入企业联系人', trigger: 'change' },
        // ],
        infoPost: [
            { required: true, message: '请输入联系人职务', trigger: 'change' },
        ],
        // address: [
        //     { required: true, message: '请输入详细地址', trigger: 'change' },
        // ],
        // industryTypeId: [
        //     { required: true, message: '请选择所属行业', trigger: 'change' },
        // ],
        // mainBusiness: [
        //     { required: true, message: '请输入企业的主营业务', trigger: 'change' },
        // ],
        // informatizationLevel: [
        //     { required: true, message: '请输入企业的信息化程度', trigger: 'change' },
        // ],
        // productionLine: [
        //     { required: true, message: '请输入产线', trigger: 'change' },
        // ],
        // projectDesc: [
        //     { required: true, message: '请输入客户需求', trigger: 'change' },
        // ],
        crmGender: [
            { required: true, message: '请选择性别', trigger: 'change' },
        ],
        crmIndustryId: [
            { required: true, message: '请选择所属行业', trigger: 'change' },
        ],
        crmMainCustomer: [
            { required: true, message: '请输入主要客户', trigger: 'change' },
        ],
        crmInformatizationLevel: [
            { required: true, message: '请选择信息化程度', trigger: 'change' },
        ],
        crmEnterpriseNature: [
            { required: true, message: '请选择企业性质', trigger: 'change' },
        ],
        crmPvSize: [
            { required: true, message: '请选择产值规模', trigger: 'change' },
        ],
        crmStaffSize: [
            { required: true, message: '请选择人员规模', trigger: 'change' },
        ],
    };
    private options: any = [];
    private industryTypeList: any = [];
    private infoLevelList: any = [];
    private enterpriseNatureList: any = [];
    private crmPvSizeList: any = [];
    private crmStaffSizeList: any = [];
    private genderList: any = [
        {
            id: 1,
            name: '男',
        },
        {
            id: 2,
            name: '女',
        },
    ];
    private crmRow: any = {};
    private searchVisible: boolean = false;
    private timeout: any = null;
    private created() {
        if (this.customerId !== 0) {
            this.getCustomerDetail();
        }
    }
    get searchPlaceholder() {
        if (this.activePage === 1) {
            return '请输入报备企业名称查询';
        }
        if (this.activePage === 3) {
            return '报备企业名称';
        }
        return '';
    }
    private validateName(rule: any, value: string, callback: any) {
        if (value === '' && this.activePage === 3) {
            callback(new Error('请先查询需要报备的企业'));
        }  else if (value === '' && this.activePage !== 3) {
            callback(new Error('请输入报备企业名称'));
        } else {
            callback();
        }
    }
    private validateProjectDesc(rule: any, value: string, callback: any) {
        if (value === '') {
            callback(new Error('请输入客户需求'));
        } else {
            callback();
        }
    }
    private getConfigList() {
        this.$httpService.getData({}, `/apiProxy/api/frontend/company/customer/dict/value/list`)
        .then((res: any) => {
            this.industryTypeList = [];
            this.infoLevelList = [];
            this.enterpriseNatureList = [];
            this.crmPvSizeList = [];
            this.crmStaffSizeList = [];
            if (res && res.length > 0) {
                res.forEach((item: any) => {
                    // 所属行业
                    if (item.type === 1) {
                        this.industryTypeList.push(item);
                    }
                    // 信息化程度
                    if (item.type === 2) {
                        this.infoLevelList.push(item);
                    }
                    // 企业性质
                    if (item.type === 3) {
                        this.enterpriseNatureList.push(item);
                    }
                    // 产值规模
                    if (item.type === 4) {
                        this.crmPvSizeList.push(item);
                    }
                    // 人员规模
                    if (item.type === 5) {
                        this.crmStaffSizeList.push(item);
                    }
                });
            }
        });
    }
    private getCustomerDetail() {
        this.$httpService.getData({}, `/apiProxy/api/frontend/company/customer/${this.customerId}`)
        .then((res: any) => {
            this.customerData = {
                name: res.name,
                taxpayerId: res.taxpayerId,
                contacts: res.contacts,
                mobile: res.mobile,
            };
            this.addCustomerData = {
                infoPost: res.infoPost,
                // address: res.address,
                // industryTypeId: res.industryTypeId + '',
                // mainBusiness: res.mainBusiness,
                // informatizationLevel: res.informatizationLevel,
                // productionLine: res.productionLine,
                // storehouseNumber: res.storehouseNumber,
                // projectDesc: res.projectDesc,
                crmGender: res.crmGender,
                crmIndustryId: res.crmIndustryId,
                crmMainCustomer: res.crmMainCustomer,
                mainBusiness: res.mainBusiness,
                crmInformatizationLevel: res.crmInformatizationLevel
                    && res.crmInformatizationLevel !== '' ? res.crmInformatizationLevel.split(',') : [],
                crmEnterpriseNature: res.crmEnterpriseNature,
                crmPvSize: res.crmPvSize,
                crmStaffSize: res.crmStaffSize,
                crmFactoryNum: res.crmFactoryNum,
                crmFactoryAddress: res.crmFactoryAddress,
            };
            if (res.crmReport && res.crmReport === 1) {
                this.activePage = 3;
                this.crmRow = res;
            } else if (res.taxpayerId && res.taxpayerId !== '') {
                this.activePage = 1;
                this.prevCustomerName = res.name;
                this.searchIdentifier(false);
            } else {
                this.activePage = 2;
            }
        });
    }
    // 给父组件传值
    @Emit('backHandle')
    private backHandleTodo(refreshFlag: boolean) {
        return refreshFlag;
    }
    private goBackHandle() {
       this.backHandleTodo(false);
    }
    private nextStepHandle(formName: any) {
        const elForm = this.$refs[formName]! as ElForm;
        elForm.validate((valid: boolean) => {
            if (valid) {
                this.checkCustomer();
            }
        });
    }
    private checkCustomer() {
        if (this.customerData.mobile !== '' && !verifyPhone(this.customerData.mobile)) {
            this.$message.warning('手机号格式不正确!');
            return;
        }
        const paramData: any = {};
        paramData.mobile = this.customerData.mobile;
        paramData.name = this.customerData.name;
        paramData.taxpayerId = this.customerData.taxpayerId;
        paramData.specialReport = this.activePage === 2 ? 1 : 0;
        if (this.customerId !== 0) {
            paramData.id = this.customerId;
        }
        paramData.crmReport = this.activePage === 3 ? 1 : 0;
        this.$httpService.postData(paramData, '/apiProxy/api/frontend/company/customer/info/check')
        .then((res: any) => {
            // this.getIndustryType();
            this.getConfigList();
            this.activeIndex = 2;
            if (this.customerId === 0) {
                this.resetForm('refAddCustomer');
            }
            if (this.activePage === 3) {
                this.setCrmItem();
            }
        });
    }
    private setCrmItem() {
        const res = this.crmRow;
        this.addCustomerData = {
            infoPost: res.infoPost,
            crmGender: res.crmGender,
            crmIndustryId: res.crmIndustryId,
            crmMainCustomer: res.crmMainCustomer,
            mainBusiness: res.mainBusiness,
            crmInformatizationLevel: res.crmInformatizationLevel
                && res.crmInformatizationLevel !== '' ? res.crmInformatizationLevel.split(',') : [],
            crmEnterpriseNature: res.crmEnterpriseNature,
            crmPvSize: res.crmPvSize,
            crmStaffSize: res.crmStaffSize,
            crmFactoryNum: res.crmFactoryNum,
            crmFactoryAddress: res.crmFactoryAddress,
        };
    }
    private getIndustryType() {
        const paramData: any = {};
        this.$httpService.getData(paramData, '/apiProxy/api/frontend/company/customer/industry-type')
        .then((res: any) => {
            this.industryTypeList = [];
            if (res.length === 0) {
                return;
            }
            res.forEach((item: any) => {
                this.industryTypeList.push({
                    id: item.id + '',
                    name: item.name,
                });
            });
            // this.industryTypeList = res;
        });
    }
    private submitAddCustomer(formName: any) {
        const elForm = this.$refs[formName]! as ElForm;
        elForm.validate((valid: boolean) => {
            if (valid) {
                if (this.customerId !== 0) {
                    this.againSubmitCustomer();
                } else {
                    this.submitCustomer();
                }
            }
        });
    }
    private submitCustomer() {
        const paramData: any = {};
        paramData.mobile = this.customerData.mobile;
        paramData.name = this.customerData.name;
        paramData.taxpayerId = this.customerData.taxpayerId;
        paramData.specialReport = this.activePage === 2 ? 1 : 0; // 是否特殊报备
        paramData.contacts = this.customerData.contacts;
        // 第二步
        // paramData.infoPost = this.addCustomerData.infoPost;
        // paramData.address = this.addCustomerData.address;
        // paramData.industryTypeId = Number(this.addCustomerData.industryTypeId);
        // paramData.mainBusiness = this.addCustomerData.mainBusiness;
        // paramData.informatizationLevel = this.addCustomerData.informatizationLevel;
        // paramData.productionLine = this.addCustomerData.productionLine;
        // paramData.storehouseNumber = this.addCustomerData.storehouseNumber;
        // paramData.projectDesc = this.addCustomerData.projectDesc;
        paramData.infoPost = this.addCustomerData.infoPost;
        paramData.crmGender = this.addCustomerData.crmGender;
        paramData.crmIndustryId = this.addCustomerData.crmIndustryId;
        paramData.crmMainCustomer = this.addCustomerData.crmMainCustomer;
        paramData.mainBusiness = this.addCustomerData.mainBusiness;
        paramData.crmInformatizationLevel = this.addCustomerData.crmInformatizationLevel.toString();
        paramData.crmEnterpriseNature = this.addCustomerData.crmEnterpriseNature;
        paramData.crmPvSize = this.addCustomerData.crmPvSize;
        paramData.crmStaffSize = this.addCustomerData.crmStaffSize;
        paramData.crmFactoryNum = this.addCustomerData.crmFactoryNum;
        paramData.crmFactoryAddress = this.addCustomerData.crmFactoryAddress;
        paramData.crmReport = this.activePage === 3 ? 1 : 0;
        this.$httpService.postData(paramData, '/apiProxy/api/frontend/company/customer')
        .then((res: any) => {
            this.$alert('预计1-3个工作日内完成审核，审核结果会以短信形式通知您。', '提交成功，等待审核', {
                showClose: false,
                confirmButtonText: '我知道了',
                callback: (action) => {
                    this.backHandleTodo(true);
                },
            });
        });
    }
    private againSubmitCustomer() {
        // if (this.addCustomerData.industryTypeId === '') {
        //     this.$message.warning('请选择所属行业');
        //     return;
        // }
        const paramData: any = {};
        paramData.mobile = this.customerData.mobile;
        paramData.name = this.customerData.name;
        paramData.taxpayerId = this.customerData.taxpayerId;
        paramData.specialReport = this.activePage === 2 ? 1 : 0;
        paramData.contacts = this.customerData.contacts;
        // 第二步
        paramData.infoPost = this.addCustomerData.infoPost;
        // paramData.address = this.addCustomerData.address;
        // paramData.industryTypeId = Number(this.addCustomerData.industryTypeId);
        // paramData.mainBusiness = this.addCustomerData.mainBusiness;
        // paramData.informatizationLevel = this.addCustomerData.informatizationLevel;
        // paramData.productionLine = this.addCustomerData.productionLine;
        // paramData.storehouseNumber = this.addCustomerData.storehouseNumber;
        // paramData.projectDesc = this.addCustomerData.projectDesc;
        paramData.crmGender = this.addCustomerData.crmGender;
        paramData.crmIndustryId = this.addCustomerData.crmIndustryId;
        paramData.crmMainCustomer = this.addCustomerData.crmMainCustomer;
        paramData.mainBusiness = this.addCustomerData.mainBusiness;
        paramData.crmInformatizationLevel = this.addCustomerData.crmInformatizationLevel.toString();
        paramData.crmEnterpriseNature = this.addCustomerData.crmEnterpriseNature;
        paramData.crmPvSize = this.addCustomerData.crmPvSize;
        paramData.crmStaffSize = this.addCustomerData.crmStaffSize;
        paramData.crmFactoryNum = this.addCustomerData.crmFactoryNum;
        paramData.crmFactoryAddress = this.addCustomerData.crmFactoryAddress;
        paramData.crmReport = this.activePage === 3 ? 1 : 0;
        this.$httpService.putData(paramData, `/apiProxy/api/frontend/company/customer/${this.customerId}`)
        .then((res: any) => {
            this.$alert('预计1-3个工作日内完成审核，审核结果会以短信形式通知您。', '提交成功，等待审核', {
                showClose: false,
                confirmButtonText: '我知道了',
                callback: (action) => {
                    this.backHandleTodo(true);
                },
            });
        });
    }
    private prevStepHandle(formName: any) {
        if (this.customerId === 0) {
            this.addCustomerData = {
                infoPost: '',
                crmGender: '',
                crmIndustryId: '',
                crmMainCustomer: '',
                mainBusiness: '',
                crmInformatizationLevel: [],
                crmEnterpriseNature: '',
                crmPvSize: '',
                crmStaffSize: '',
                crmFactoryNum: '',
                crmFactoryAddress: '',
            };
            this.resetForm('refAddCustomer');
        }
        this.activeIndex = 1;
    }
    private addSpecialCustomer(isCrm = false) {
        this.customerData = {
            name: '',
            taxpayerId: '',
            contacts: '',
            mobile: '',
        };
        this.addCustomerData = {
            infoPost: '',
            crmGender: '',
            crmIndustryId: '',
            crmMainCustomer: '',
            mainBusiness: '',
            crmInformatizationLevel: [],
            crmEnterpriseNature: '',
            crmPvSize: '',
            crmStaffSize: '',
            crmFactoryNum: '',
            crmFactoryAddress: '',
        };
        if (this.activeIndex === 2) {
            this.resetForm('refAddCustomer');
        }
        if (isCrm) {
            this.activePage = 3;
        } else {
            this.activePage = 2;
        }
        this.activeIndex = 1;
        if (this.activeIndex === 1) {
            this.resetForm('refCustomer');
        }
    }
    private resetForm(formName: any) {
        const elForm = this.$refs[formName]! as ElForm;
        elForm.resetFields();
    }
    private querySearchAsync(queryString: any, cb: any) {
        clearTimeout(this.timeout);
        this.timeout = setTimeout(() => {
            this.options.forEach((item: any) => {
                item.value = item.name;
            });
            cb(this.options);
        }, 3000 * Math.random());
    }
    private hideDropdown() {
        this.dropdownFlag = false;
        this.blurCustomerName();
    }
    private searchCustomer() {
        if (this.customerData.name === '' && this.prevCustomerName === '') {
            this.$message.warning('请输入需要查询的报备企业名称');
            return;
        } else if (this.customerData.name === '' && this.prevCustomerName !== '') {
            return;
        } else {
            this.searchIdentifier(true);
        }
    }
    private searchIdentifier(prevFlag: boolean) {
        const paramData: any = {};
        paramData.keyword = this.customerData.name;
        this.$httpService.getData(paramData, '/apiProxy/api/frontend/util/company/identifier')
        .then((res: any) => {
            this.options = res;
            // this.prevCustomerName = '';
            if (prevFlag) {
                this.dropdownFlag = true;
            }
        });
    }
    private clickItem(item: any) {
        this.prevCustomerName = item.name;
        this.customerData.name = item.name;
        this.customerData.taxpayerId = item.code;
        if (this.activePage === 3) {
            this.crmRow = item;
            this.customerData.contacts = item.contacts;
            this.customerData.mobile = item.mobile;
        }
        this.dropdownFlag = false;
    }
    private blurCustomerName() {
        if (this.activePage === 2) {
            return;
        }
        clearTimeout(this.timeout);
        this.timeout = setTimeout(() => {
            if (this.prevCustomerName !== '') {
                if (this.options.length === 0 && this.customerId === 0) {
                    this.customerData.taxpayerId = '';
                }
                this.options.forEach((item: any) => {
                    if (item.name === this.customerData.name && item.code === this.customerData.taxpayerId) {
                        // ...
                    } else {
                        this.customerData.name = this.prevCustomerName;
                    }
                });
            }
        }, 200);
    }
    private crmCustomer() {
        this.searchVisible = true;
    }
    private closeSearchCrm(row: any) {
        if (row.handleType === 1) {
            this.crmRow = row;
            this.customerData.name = row.name || '';
            this.customerData.taxpayerId = row.taxpayerId || '';
            this.customerData.contacts = row.contacts || '';
            this.customerData.mobile = row.mobile || '';
        }
        this.searchVisible = false;
    }
}
