
import '@/assets/css/iframeStyle.scss';
import { Component, Vue } from 'vue-property-decorator';
import ContentTitle from "@/components/ContentTitle.vue";
import AddCustomer from "./component/AddCustomer.vue";
import InviteDialog from "./component/InviteDialog.vue";
import FollowDialog from "./component/followDialog.vue";
import UnboundDialog from "./component/unboundDialog.vue";
import CustomerDetail from "./component/CustomerDetail.vue";
import SetResponsible from "./component/SetResponsible.vue";
import ReportAuth from "./component/ReportAuth.vue";
import { copyGetParam, verifyPhone } from '@/utils/utils';
import { toCode } from '@/utils/encrypt';

@Component({
    name: "ReportCustomer",
    components: {
        ContentTitle,
        AddCustomer,
        InviteDialog,
        CustomerDetail,
        FollowDialog,
        UnboundDialog,
        SetResponsible,
        ReportAuth,
    },
})
export default class ReportCustomer extends Vue {
    private loading: boolean = false;
    private height: any = 'calc(100vh - 280px)';
    private contentTitle: string = "报备客户";
    private activePage: number = 1; // 1、主页面；2、添加报备客户；3、报备用户详情；
    private searchParam: any = {
        pageSize: 10,
        pageNo: 1,
        name: '',
        mobile: '',
        statusList: '',
        followRecord: '',
    };
    private pageNo: number = 1;
    private pageSize: number = 10;
    private total: number = 0;
    private tableData = [];
    private unboundDialog: boolean = false;
    private followDialog: boolean = false;
    private invitationDialog: boolean = false;
    private currentRow: any = {};
    private gotoType: string = '';
    private partnerStatus: number = 3; // 伙伴状态(0, "已过期"),(1, "待签约"), (2, "合作中"), (3, "未加入")
    private checkAll: boolean = false;
    private isIndeterminate: boolean = false;
    private multipleSelection = [];
    private selectionIds: any = [];
    private responsibleStaff: any = [];
    private setRespDialog: boolean = false;
    private setRespTitle: string = '设置负责人';
    private reportAuthDialog: boolean = false;
    private created() {
        this.partnerStatus = this.$store.state.userInfo.partnerStatus;
        if (this.$route.fullPath.indexOf('type=') > -1) {
            this.gotoType = this.$route.query.type + '';
            if (this.gotoType === 'project'
                || this.gotoType === 'authorize'
                || this.gotoType === 'ecology'
                || this.gotoType === 'deviceDriveBuy') {
                this.activePage = 2;
            }
        }
        this.$nextTick( () => {
            this.getCustomerList();
        });
    }
    private mounted() {
        this.height = window.innerHeight - 295;
        window.onresize = () => {
            return (() => {
                this.height = window.innerHeight - 295;
            })();
        };
    }
    private changeMobileNum() {
        this.searchParam.mobile = this.searchParam.mobile.replace(/[^\d]/g, '');
    }
    private getCurrentRequest() {
        const paramData: any = {};
        paramData.preventRepeat = 1;
        paramData.notLoading = true;
        this.$httpService.getData(paramData, '/apiProxy/api/frontend/users/current').then((res: any) => {
            if (res.partnerStatus !== 3 && res.partnerStatus !== 4) {
                // ...
            } else {
                this.$router.push('/company/403').catch((err) => err);
            }
        });
    }
    private getCustomerList() {
        this.loading = true;
        this.tableData = [];
        let paramData: any = {};
        paramData = copyGetParam(this.searchParam);
        this.$httpService.getData(paramData, '/apiProxy/api/frontend/company/customer').then((res: any) => {
            this.tableData = res.list;
            this.total = res.total;
            this.loading = false;
            this.checkAll = false;  // 重置全选
        });
        this.getCurrentRequest();
    }
    private onSearch() {
        this.total = 0;
        this.searchParam.pageNo = 1;
        this.searchParam.pageSize = 10;
        this.getCustomerList();
    }
    private resetHandle(formName: any) {
        this.searchParam = {
            name: '',
            mobile: '',
            statusList: '',
            followRecord: '',
        };
        this.onSearch();
    }
    private handleSizeChange(val: number) {
        this.searchParam.pageSize = val;
        this.getCustomerList();
    }
    private handleCurrentChange(val: number) {
        this.searchParam.pageNo = val;
        this.getCustomerList();
    }
    private detailClick(row: any) {
        this.currentRow = row;
        this.activePage = 3;
    }
    private deleteClick(row: any) {
        this.$confirm(`确定要删除报备企业“${row.name}”吗？`, '删除', {
            confirmButtonText: '确定删除',
            cancelButtonText: '取消',
            type: 'warning',
            confirmButtonClass: 'el-button--danger',
        }).then(() => {
            // 确定
            this.currentRow = row;
            this.deleteRequest();
        }).catch(() => {
            // 取消
        });
    }
    private deleteRequest() {
        const paramData: any = {};
        paramData.id = this.currentRow.id;
        this.$httpService.deleteData(paramData, `/apiProxy/api/frontend/company/customer/${this.currentRow.id}`)
        .then((res: any) => {
            this.$message.success('删除成功');
            this.getCustomerList();
        });
    }
    private inviteClick(row: any) {
        this.invitationDialog = true;
    }
    private followClick(row: any) {
        this.currentRow = row;
        this.followDialog = true;
    }
    private unboundClick(row: any) {
        this.currentRow = row;
        this.unboundDialog = true;
    }
    private againSubmitClick(row: any) {
        this.currentRow = row;
        this.activePage = 2;
    }
    private propAgain() {
        this.activePage = 2;
    }
    // 代客下单
    private agentOrderClick(row: any) {
        this.$router.push(`/company/resource/resourceBuy?userData=${toCode(row.customerUserId + '')}`)
        .catch((err) => err);
        // /v2/user/goods/buyGoods.htm?serverId=&userData=&customerCompanyName=xxxx
        // if (row.dockerStatus === 0) {
        //     const url = `/company/redirectPage?url=/user/server/customer/paasopening.htm`;
        //     let urlParam = '';
        //     if (row.customerCompanyId && row.customerCompanyId !== null) {
        //         urlParam += `&companyId=${row.customerCompanyId}`;
        //     }
        //     this.$router.push(url + urlParam).catch((err) => err);
        // } else {
        //     this.$router.push(`/company/resource/resourceBuy?userData=${row.customerUserId}`)
        //     .catch((err) => err);
        // }
    }
    // 云服务器
    private cloudServerClick(row: any) {
        this.$router.push(`/company/resource?userData=${toCode(row.customerUserId + '')}`)
        .catch((err) => err);
    }
    private addCustomer() {
        this.currentRow = {};
        this.activePage = 2;
    }
    private propBack(value: boolean) {
        if (this.gotoType === 'project' && value === false) {
            this.$router.push('/company/projectList').catch((err) => err);
        } else if (this.gotoType === 'authorize' && value === false) {
            this.$router.push('/company/authorize').catch((err) => err);
        } else if (this.gotoType === 'deviceDriveBuy' && value === false) {
            this.$router.push('/company/deviceDriveBuy').catch((err) => err);
        } else if (this.gotoType === 'ecology') {
            this.$router.push('/company/ecoPartner').catch((err) => err);
        } else {
            this.activePage = 1;
            if (value) {
                this.getCustomerList();
            }
        }
    }
    private unboundClose(num: number) {
        if (num === 2) {
            this.getCustomerList();
        }
        this.unboundDialog = false;
    }
    private followClose(num: number) {
        if (num === 2) {
            this.getCustomerList();
        }
        this.followDialog = false;
    }
    private invitationClose() {
        this.invitationDialog = false;
    }
    private toggleSelection(val: boolean) {
        const multipleTable: any = this.$refs.multipleTable;
        if (val) {
            multipleTable.toggleAllSelection();
        } else {
            multipleTable.clearSelection();
        }
    }
    private handleSelectionChange(val: any) {
        const checkedCount = val.length;
        this.checkAll = checkedCount === this.tableData.length;
        this.isIndeterminate = checkedCount > 0 && checkedCount < this.tableData.length;
        this.multipleSelection = val.map((item: any) => (item.id));
    }
    private setStaff(row: any) {
        if (row.responsibleStaffId) {
            this.responsibleStaff = [{
                id: row.responsibleStaffId,
                name: row.responsibleStaff,
            }];
        } else {
            this.responsibleStaff = [];
        }
        this.selectionIds = [row.id];
        this.setRespTitle = '设置负责人';
        this.setRespDialog = true;
    }
    private batchSetStaff() {
        if (this.multipleSelection.length <= 0) {
            this.$message.warning('请先选择需要设置负责人的报备客户');
            return;
        }
        this.responsibleStaff = [];
        this.selectionIds = this.multipleSelection;
        this.setRespTitle = '批量设置负责人';
        this.setRespDialog = true;
    }
    private setRespClose(num: number) {
        this.setRespDialog = false;
        if (num === 2) {
            // 设置成功
            this.getCustomerList();
        }
    }
    private reportAuthOpen() {
        this.reportAuthDialog = true;
    }
    private reportAuthClose(num: number) {
        this.reportAuthDialog = false;
        console.log(num);
        if (num > 0) {
            this.getCustomerList();
        }
    }
}
