
import { Component, Vue, Prop, Watch, Emit } from 'vue-property-decorator';
@Component({
    name: 'EcologyExpire',
})
export default class EcologyExpire extends Vue {
    private userInfo: any = this.$store.state.userInfo;

    private gotoEcology() {
        this.$router.push("/company/ecoPartner").catch((err) => err);
    }
}
