
import '@/assets/css/iframeStyle.scss';
import { Component, Vue, Watch } from 'vue-property-decorator';
import ContentTitle from "@/components/ContentTitle.vue";
import SearchHeader from "@/components/BaseTable/SearchHeader.vue";
import BaseTable from "@/components/BaseTable/index.vue";
import ProjectAuthApply from './component/ProjectAuthApply.vue';
import UpdateProjectAuth from './component/UpdateProjectAuth.vue';
import AuthTips from "@/components/AuthTips.vue";
import { fromCode } from '@/utils/encrypt';

enum PageType {
    ProjectAuthPageTypeMain,
    ProjectAuthPageTypeApply,
    ProjectAuthPageTypeUpdate,
    ProjectAuthPageTypePay,
    ProjectAuthPageTypePaySuccess,
}

@Component({
    name: "ProjectList",
    components: {
        ContentTitle,
        SearchHeader,
        BaseTable,
        ProjectAuthApply,
        UpdateProjectAuth,
        AuthTips,
    },
})
export default class ProjectList extends Vue {
    private contentTitle: string = "项目列表";
    private baseUrl = process.env.VUE_APP_URL;
    // AuthTips
    private tipsIndex: number = 0; // 0，不显示；1，认证提示；2，切换账号提示
    private total = 0;
    private currentPage = 1;
    private pageType = PageType;
    private activePage = PageType.ProjectAuthPageTypeMain;
    private applyType?: number;
    private detailInfo?: any;
    private paymentOrderIds?: any;
    private searchParam = {
        pageSize: 10,
        pageNo: 1,
        customerInfoName: '',
        projectName: '',
        fromTime: '',
        endTime: '',
        projectType: null,
    };
    private reportTime: any = null;
    private tableHeader = [
        {
            width: "80",
            prop: "projectId",
            label: "项目编号",
        },
        {
            width: "260",
            prop: "projectName",
            label: "项目名称",
        },
        {
            width: "80",
            prop: "projectTypeName",
            label: "项目类型",
        },
        {
            width: "260",
            prop: "customerInfoName",
            label: "报备客户",
        },
        {
            width: "260",
            prop: "companyName",
            label: "客户名称",
        },
        {
            width: "150",
            prop: "goodsName",
            label: "商品名称",
        },
        {
            width: "150",
            prop: "usefulTime",
            label: "授权有效期",
            formatter(row: any, column: any, cellValue: any, index: any) {
                if (row.timeForever === 1) {
                    return `<span>永久</span>`;
                } else {
                    if (cellValue === '' || cellValue === undefined) {
                        return "-";
                    }
                    return `<span>${cellValue}</span>`;
                }
            },
        },
        {
            width: "120",
            prop: "userNumber",
            label: "授权总用户数",
        },
        {
            width: "120",
            prop: "licenseUrl",
            label: "授权文件",
            render: (h: any, scope: any) => {
                return h('span', {
                    class: ['text-blue'],
                    on: {
                        click: () => this.downloadFile(scope.row),
                    },
                }, 'license.lic');
            },
        },
        {
            width: "120",
            prop: "statusName",
            label: "项目状态",
            formatter(row: any, column: any, cellValue: any, index: any) {
                if (cellValue === '' || cellValue === undefined) {
                    return "-";
                }
                let className = '';
                if (cellValue === "正常") {
                    className = 'text-green';
                    return `<span class="${className}">${cellValue}</span>`;
                } else {
                    className = 'text-orange';
                    return `<span class="${className}">${cellValue}</span>`;
                }
            },
        },
        {
            width: "120",
            prop: "addTime",
            label: "初次申请时间",
        },
        {
            width: "120",
            prop: "lastTime",
            label: "最后授权时间",
        },
        {
            width: "120",
            prop: "contacts",
            label: "联系人",
        },
        {
            width: "120",
            prop: "mobile",
            label: "手机号",
        },
    ];
    private tableData = [];
    private projectId: string = '';
    private orderId: string = '';
    @Watch('reportTime')
    private watchReportTime(val: any) {
        if (val) {
            this.searchParam.fromTime = `${val[0]} 00:00:00`;
            this.searchParam.endTime = `${val[1]} 23:59:59`;
        } else {
            this.searchParam.fromTime = '';
            this.searchParam.endTime = '';
        }
    }
    private created() {
        // 新版 项目申请 需要
        if (this.$route.fullPath.indexOf('orderId=') > -1) {
            this.orderId = fromCode(this.$route.query.orderId + '');
        }
        if (this.$route.fullPath.indexOf('active=') > -1) {
            const active = this.$route.query.active + '';
            if (active === '2') {
                this.applyType = 1;
                this.activePage = PageType.ProjectAuthPageTypeApply;
            }
        }
        const projectId = this.$route.query.xId;
        if (projectId) {
            this.projectId = fromCode(this.$route.query.xId + '');
        }
        this.$nextTick(() => {
            this.getDataList();
        });
    }
    private mounted() {
        // ...
    }
    private handleSizeChange(val: any) {// 切换页数
        this.searchParam.pageNo = 1;
        this.searchParam.pageSize = val;
        this.getDataList();
    }
    private handleCurrentChange(val: any) {// 切换页码
        this.searchParam.pageNo = val;
        this.getDataList();
    }
    private getDataList() {
        const formRef: any = this.$refs.searchForm;
        formRef.validate((valid: boolean) => {
            if (valid) {
                const searchParam: any = Object.assign({}, this.searchParam);
                if (this.projectId !== '') {
                    searchParam.projectId = this.projectId;
                }
                this.$httpService.getData(searchParam, '/apiProxy/api/frontend/project/grant/project-list')
                .then((res: any) => {
                    this.projectId = '';
                    if (res && res.total > 0) {
                        this.tableData = res.list;
                        this.total = res.total;
                    } else {
                        this.tableData = [];
                        this.total = 0;
                    }
                });
            }
        });
    }
    private onSearch() {
        this.searchParam.pageNo = 1;
        this.getDataList();
    }
    private resetForm(formName: any) {
        this.searchParam = {
            pageSize: 10,
            pageNo: 1,
            customerInfoName: '',
            projectName: '',
            fromTime: '',
            endTime: '',
            projectType: null,
        };
        this.reportTime = '';
        const formRef: any = this.$refs[formName];
        formRef.resetFields();
        this.$nextTick(() => {
            this.getDataList();
        });
    }
    private updateProject(row: any, type: number) {
        this.detailInfo = row;
        // type 1：变更；2：变更转延续；3：升级；4：测试延续
        if (type === 1) {
            this.detailInfo.updating = true;
        } else {
            this.detailInfo.updating = false;
        }
        this.detailInfo.handleType = type;
        this.activePage = PageType.ProjectAuthPageTypeUpdate;
    }
    private formalAuthApply() {
        const useInfo = this.$store.state.userInfo;
        if (useInfo.userType === 2 && useInfo.certStatus !== 2) {
            this.tipsIndex = 1;
            return;
        }
        this.activePage = PageType.ProjectAuthPageTypeApply;
        this.applyType = 1;
    }
    private testAuthApply() {
        const useInfo = this.$store.state.userInfo;
        if (useInfo.userType === 2 && useInfo.certStatus !== 2) {
            this.tipsIndex = 1;
            return;
        }
        this.activePage = PageType.ProjectAuthPageTypeApply;
        this.applyType = 2;
    }
    private receiveCloseAuthTips() {
        this.tipsIndex = 0;
    }
    private backHandle(num: number) {
        this.orderId = '';
        this.activePage = PageType.ProjectAuthPageTypeMain;
        if (num === 2) {
            this.$router.push("/company/authorize").catch((err) => err);
        } else if (num === 3) {
            this.$router.push("/company/reportCustomer?type=project").catch((err) => err);
        }
    }
    private payBackHandle(obj: any) {
        const zeroOrder = obj.money;
        if (zeroOrder <= 0) {
            this.paymentOrderIds = obj.orderIds;
            this.activePage = PageType.ProjectAuthPageTypePaySuccess;
        } else {
            this.paymentOrderIds = obj.orderIds;
            this.activePage = PageType.ProjectAuthPageTypePay;
        }
    }
    private paySucToOrderList(val: string) {
        this.$router.push("/company/myOrder").catch((err) => err);
    }
    // 下载文件
    private async downloadFile(row: any) {
        if (row.licenseUrl) {
            try {
                this.$message.success('文件下载中，请稍等');
                const response = await fetch(this.baseUrl + this.$apiProxy + row.licenseUrl);
                const blob = await response.blob();
                const exts = row.licenseUrl.split('.');
                const ext = exts[exts.length - 1];
                const fileName = `license.${ext}`;
                this.triggerDownload(URL.createObjectURL(blob), fileName);
            } catch (error) {
                // ...
            }
        }
    }
    private triggerDownload(blob: any, fileName: string) {
        const a = document.createElement('a');
        document.body.appendChild(a);
        a.href = blob;
        a.download = fileName;
        a.click();
        document.body.removeChild(a);
    }
}
