
import '@/assets/css/iframeStyle.scss';
import { Component, Vue, Watch } from 'vue-property-decorator';
import ContentTitle from "@/components/ContentTitle.vue";
import SearchHeader from "@/components/BaseTable/SearchHeader.vue";
import BaseTable from "@/components/BaseTable/index.vue";
import ProjectAuthApply from '../projectList/component/ProjectAuthApply.vue';
import UpdateProjectAuth from '../projectList/component/UpdateProjectAuth.vue';
import AgainProjectApply from '../projectList/component/AgainProjectApply.vue';
import PaySuccess from "../../orderManage/list/component/PaySuccess.vue";

enum PageType {
    ProjectAuthPageTypeMain,
    ProjectAuthPageTypeApply,
    ProjectAuthPageTypeUpdate,
    ProjectAuthPageTypeAgain,
    ProjectAuthPageTypePay,
    ProjectAuthPageTypePaySuccess,
}

@Component({
    name: "Authorize",
    components: {
        ContentTitle,
        SearchHeader,
        BaseTable,
        ProjectAuthApply,
        UpdateProjectAuth,
        AgainProjectApply,
        PaySuccess,
    },
})
export default class Authorize extends Vue {
    private contentTitle: string = "授权历史";
    private baseUrl = process.env.VUE_APP_URL;
    private total = 0;
    private currentPage = 1;
    private pageType = PageType;
    private activePage = PageType.ProjectAuthPageTypeMain;
    private applyType?: number;
    private detailInfo: any = {};
    private paymentOrderIds?: any;
    private ifMain: boolean = true; // 用来重新加载主页面重置
    private searchParam = {
        pageSize: 10,
        pageNo: 1,
        customerCompanyName: '',
        projectName: '',
        fromTime: '',
        endTime: '',
        projectType: null,
    };
    private reportTime: any = null;
    private tableHeader = [
        {
            width: "80",
            prop: "licenseId",
            label: "申请编号",
        },
        {
            width: "80",
            prop: "projectId",
            label: "项目编号",
        },
        {
            width: "260",
            prop: "projectName",
            label: "项目名称",
        },
        {
            width: "80",
            prop: "projectTypeName",
            label: "项目类型",
        },
        {
            width: "260",
            prop: "customerInfoName",
            label: "报备客户",
        },
        {
            width: "260",
            prop: "customerCompanyName",
            label: "客户名称",
        },
        {
            width: "150",
            prop: "licenseType",
            label: "申请类型",
            render: (h: any, scope: any) => {
                let licenseTypeDic = "";
                switch (scope.row.licenseType) {
                    case 1:
                        licenseTypeDic = "初次申请";
                        break;
                    case 2:
                        licenseTypeDic = "服务器变更";
                        break;
                    case 3:
                        licenseTypeDic = "服务器延续";
                        break;
                    case 4:
                        licenseTypeDic = "测试项目";
                        break;
                    case 5:
                        licenseTypeDic = "Wis3D授权升级";
                        break;
                    default:
                        licenseTypeDic = "";
                }
                return h("div", [
                    h(
                        "span",
                        {},
                        licenseTypeDic,
                    ),
                ]);
            },
        },
        {
            width: "170",
            prop: "licenseAddTime",
            label: "申请时间",
        },
        {
            width: "150",
            prop: "licenseStatus",
            label: "申请状态",
            render: (h: any, scope: any) => {
                let licenseStatusDic = "";
                let colorName = "#555555";
                switch (scope.row.licenseStatus) {
                    case -1:
                        licenseStatusDic = "已关闭";
                        colorName = "gray";
                        break;
                    case 0:
                        licenseStatusDic = "待支付";
                        colorName = "orange";
                        break;
                    case 1:
                        licenseStatusDic = "待审核";
                        colorName = "orange";
                        break;
                    case 2:
                        licenseStatusDic = "待制作";
                        colorName = "orange";
                        break;
                    case 3:
                        licenseStatusDic = "审核不通过";
                        colorName = "red";
                        break;
                    case 4:
                        licenseStatusDic = "已授权";
                        colorName = "green";
                        break;
                    default:
                        licenseStatusDic = "";
                }
                return h("div",
                    {
                        style: { color: colorName , cursor: scope.row.licenseStatus === 3 ? "pointer" : "auto"},
                        on: {
                            click: () => {
                                if (scope.row.licenseStatus === 3) {
                                    this.$alert(scope.row.checkLog, '审核不通过原因', {
                                        confirmButtonText: '确定',
                                        type: 'warning',
                                        callback: (action) => {
                                            // ...
                                        },
                                    });
                                }
                            },
                        },
                    }, [
                        h(
                            "span",
                            licenseStatusDic,
                        ),
                        h(
                            "i",
                            {
                                style: { marginLeft: "6px", fontSize: "18px", marginTop: "-3px" },
                                class: [scope.row.licenseStatus === 3 ? "el-icon-warning-outline" : ""],
                            },
                        ),
                    ]);
            },
        },
        {
            width: "150",
            prop: "licenseEndTimeStr",
            label: "授权有效期（申请）",
        },
        {
            width: "140",
            prop: "licenseNumber",
            label: "原/本次授权用户数",
            render: (h: any, scope: any) => {
                let userNumberHtml = "-";
                if (scope.row.goodsId === 4) {
                    userNumberHtml = scope.row.allLicenseNumber + " / " + scope.row.licenseNumber;
                }
                return h("div", [
                    h(
                        "span",
                        {
                            style: { color: "#555555" },
                        },
                        userNumberHtml,
                    ),
                ]);
            },
        },
        {
            width: "150",
            prop: "payMoney",
            label: "支付金额",
            formatter: (row: any, column: any, cellValue: any, index: any) => {
                if (cellValue === "-") {
                    return cellValue;
                }
                if (!cellValue) {
                    if (cellValue === 0) {
                        return "0.00";
                    } else {
                        return "-";
                    }
                }
                return Number(cellValue).toFixed(2);
            },
        },
        {
            width: "270",
            prop: "licenseRegisterUrl",
            label: "注册文件",
            render: (h: any, scope: any) => {
                if (scope.row.licenseRegisterUrl) {
                    const arr = scope.row.licenseRegisterUrl.split("\/");
                    const fileArr = arr[arr.length - 1].split(".");
                    return h('span', {
                        class: ['text-blue'],
                        on: {
                            click: () => this.downloadFile(scope.row.licenseRegisterUrl, fileArr[0]),
                        },
                    }, arr[arr.length - 1]);
                } else {
                    return h('span', {}, '-');
                }
            },
        },
        {
            width: "120",
            prop: "licenseUrl",
            label: "授权文件",
            render: (h: any, scope: any) => {
                if (scope.row.licenseUrl) {
                    return h('span', {
                        class: ['text-blue'],
                        on: {
                            click: () => this.downloadFile(scope.row.licenseUrl),
                        },
                    }, 'license.lic');
                } else {
                    return h('span', {}, '-');
                }
            },
        },
        {
            width: "120",
            prop: "projectContacts",
            label: "联系人",
        },
        {
            width: "120",
            prop: "projectContactsMobile",
            label: "手机号",
        },
    ];
    private tableData = [];
    @Watch('reportTime')
    private watchReportTime(val: any) {
        if (val) {
            this.searchParam.fromTime = `${val[0]} 00:00:00`;
            this.searchParam.endTime = `${val[1]} 23:59:59`;
        } else {
            this.searchParam.fromTime = '';
            this.searchParam.endTime = '';
        }
    }
    private mounted() {
        this.$nextTick(() => {
            this.getDataList();
        });
    }
    private handleSizeChange(val: any) {// 切换页数
        this.searchParam.pageNo = 1;
        this.searchParam.pageSize = val;
        this.getDataList();
    }
    private handleCurrentChange(val: any) {// 切换页码
        this.searchParam.pageNo = val;
        this.getDataList();
    }
    private getDataList() {
        const formRef: any = this.$refs.searchForm;
        formRef.validate((valid: boolean) => {
            if (valid) {
                const searchParam: any = Object.assign({}, this.searchParam);
                this.$httpService.getData(searchParam, '/apiProxy/api/frontend/project/grant/history')
                .then((res: any) => {
                    if (res && res.total > 0) {
                        this.tableData = res.list;
                        this.total = res.total;
                    } else {
                        this.tableData = [];
                        this.total = 0;
                    }
                });
            }
        });
    }
    private onSearch() {
        this.searchParam.pageNo = 1;
        this.getDataList();
    }
    private resetForm(formName: any) {
        this.searchParam = {
            pageSize: 10,
            pageNo: 1,
            customerCompanyName: '',
            projectName: '',
            fromTime: '',
            endTime: '',
            projectType: null,
        };
        this.reportTime = '';
        const formRef: any = this.$refs[formName];
        formRef.resetFields();
        this.$nextTick(() => {
            this.getDataList();
        });
    }
    private formalAuthApply() {
        this.activePage = PageType.ProjectAuthPageTypeApply;
        this.applyType = 1;
    }
    private updateProject(row: any, type: number) {
        this.detailInfo = row;
        // type 1：变更；2：变更转延续；3：升级；4：测试延续
        if (type === 1) {
            this.detailInfo.updating = true;
        } else {
            this.detailInfo.updating = false;
        }
        this.detailInfo.handleType = type;
        this.activePage = PageType.ProjectAuthPageTypeUpdate;
    }
    private againProject(row: any) {
        this.detailInfo = row;
        this.activePage = PageType.ProjectAuthPageTypeAgain;
    }
    private payProject(row: any) {
        this.$httpService.postData({}, `/apiProxy/api/frontend/project/grant/order-payment/${row.licenseId}`)
        .then((res: any) => {
            const zeroOrder = res.money;
            if (zeroOrder <= 0 || res.isPaySuccess) {
                this.paymentOrderIds = res.orderIds;
                this.activePage = PageType.ProjectAuthPageTypePaySuccess;
            } else {
                this.paymentOrderIds = res.orderIds;
                this.activePage = PageType.ProjectAuthPageTypePay;
            }
        });
    }
    private paySucToOrderList(val: string) {
        this.$router.push("/company/myOrder").catch((err) => err);
    }
    private closeProject(row: any) {
        this.$confirm(`确认取消当前授权申请？`, '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning',
        }).then(() => {
            // 确定
            this.$httpService.putData({}, `/apiProxy/api/frontend/project/grant/cancel-application/${row.licenseId}`)
            .then((res: any) => {
                this.$message.success('取消成功');
                this.onSearch();
            });
        }).catch(() => {
            // 取消
        });

    }

    private backHandle(num: number) {
        this.activePage = PageType.ProjectAuthPageTypeMain;
        if (num === 2) {
            // this.$router.push("/company/authorize").catch((err) => err);
            // this.ifMain = false;
            // setTimeout(() => {
            //     this.ifMain = true;
            // }, 100);
            this.onSearch();
        } else if (num === 3) {
            this.$router.push("/company/reportCustomer?type=authorize").catch((err) => err);
        }
    }
    private payBackHandle(obj: any) {
        const zeroOrder = obj.money;
        if (zeroOrder <= 0) {
            this.paymentOrderIds = obj.orderIds;
            this.activePage = PageType.ProjectAuthPageTypePaySuccess;
        } else {
            this.paymentOrderIds = obj.orderIds;
            this.activePage = PageType.ProjectAuthPageTypePay;
        }
    }
    // 下载文件
    private async downloadFile(fileUrl: any, fileNameStr = 'license') {
        if (fileUrl) {
            try {
                this.$message.success('文件下载中，请稍等');
                const response = await fetch(this.baseUrl + this.$apiProxy + fileUrl);
                const blob = await response.blob();
                const exts = fileUrl.split('.');
                const ext = exts[exts.length - 1];
                const fileName = `${fileNameStr}.${ext}`;
                this.triggerDownload(URL.createObjectURL(blob), fileName);
            } catch (error) {
                // ...
            }
        }
    }
    private triggerDownload(blob: any, fileName: string) {
        const a = document.createElement('a');
        document.body.appendChild(a);
        a.href = blob;
        a.download = fileName;
        a.click();
        document.body.removeChild(a);
    }
}
