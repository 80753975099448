
import { ElForm } from 'node_modules/element-ui/types/form';
import { Component, Vue, Prop, Watch, Emit } from 'vue-property-decorator';

@Component({
  name: 'SetResponsible',
})
export default class SetResponsible extends Vue {
    private formData: any = {
        staffId: '',
    };
    private rules: any = {
        staffId: [
            { required: true, message: '请选择负责人', trigger: 'change' },
        ],
    };
    private options: any = [];
    @Prop({
        type: Boolean,
        default: false,
    }) private EditFlag!: boolean; // 接收父组件传过来的值
    @Prop({
        type: String,
        default: '设置负责人',
    }) private dialogTitle!: string;
    @Prop({
        type: Array,
        default: 0,
    }) private selectionIds!: number[];
    @Prop({
        type: Array,
        default: [],
    }) private responsibleStaff!: any[];
    private showDialog: boolean = false; // 弹窗显示与关闭
    @Watch('EditFlag') // 监听父组件传过来的值
    private EditFlagFun(value: boolean) {
        this.showDialog = value;
        if (value) {
            if (this.responsibleStaff.length) {
                this.formData.staffId = this.responsibleStaff[0].id;
            }
            this.getStaffList();
        }
    }
    // 右上角的关闭按钮
    private beforeClose() {
        this.closeHandleTodo(1); // 给父组件传值
    }
    // 给父组件传值
    @Emit('closeHandle')
    private closeHandleTodo(num: number): number {
        this.showDialog = false;
        this.formData = {
            staffId: '',
        };
        this.resetForm('formRef');
        return num;
    }
    // private created() {
    //     this.getStaffList();
    // }
    private getStaffList(query?: any) {
        if (query !== '') {
            const paramData: any = {
                pageNo: 1,
                pageSize: 500,
                name: query,
            };
            this.$httpService.getData(paramData, '/apiProxy/api/frontend/company/staff').then((res: any) => {
                this.options = res.list;
            });
        } else {
            this.options = [];
        }
    }
    private resetForm(formName: any) {
        const elForm = this.$refs[formName]! as ElForm;
        elForm.resetFields();
    }
    private submit(formName: any) {
        const elForm = this.$refs[formName]! as ElForm;
        elForm.validate((valid: boolean) => {
            if (valid) {
                if (this.formData.staffId) {
                    this.submitRequest();
                } else {
                    this.clearRequest();
                }
            }
        });
    }
    // 设置负责人
    private submitRequest() {
        const paramData: any = {};
        paramData.customers = this.selectionIds;
        this.$httpService
        .putData(paramData, `/apiProxy/api/frontend/customer/staff-assignment/${this.formData.staffId}`)
        .then((res: any) => {
            this.$message.success('设置成功');
            this.closeHandleTodo(2); // 给父组件传值
        });
    }
    // 清空负责人
    private clearRequest() {
        const paramData: any = {};
        paramData.customers = this.selectionIds;
        this.$httpService
        .deleteData(paramData, `/apiProxy/api/frontend/customer/staff-assignment/customer/principal`)
        .then((res: any) => {
            this.$message.success('设置成功');
            this.closeHandleTodo(2); // 给父组件传值
        });
    }
}
