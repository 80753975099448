
import { ElForm } from 'node_modules/element-ui/types/form';
import { Component, Vue, Prop, Watch, Emit } from 'vue-property-decorator';

@Component({
  name: 'ReportAuth',
})
export default class ReportAuth extends Vue {
    @Prop({
        type: Boolean,
        default: false,
    }) private EditFlag!: boolean; // 接收父组件传过来的值
    private showDialog: boolean = false; // 弹窗显示与关闭
    private tableData = [];
    private searchParam = {
        pageNo: 1,
        pageSize: 5,
    };
    private total: number = 0;
    private count: number = 0;
    @Watch('EditFlag') // 监听父组件传过来的值
    private EditFlagFun(value: boolean) {
        this.showDialog = value;
        if (value) {
            this.count = 0;
            this.searchParam.pageNo = 1;
            this.getDataList();
        }
    }
    // 右上角的关闭按钮
    private beforeClose() {
        this.closeHandleTodo(); // 给父组件传值
    }
    // 给父组件传值
    @Emit('closeHandle')
    private closeHandleTodo(): number {
        this.showDialog = false;
        return this.count;
    }
    private getDataList() {
        // this.tableData = [];
        this.$httpService.getData(this.searchParam, '/apiProxy/api/frontend/customer/staff-assignment')
        .then((res: any) => {
            this.tableData = res.list;
            this.total = res.total;
        });
    }
    private handleCurrentChange(val: number) {
        this.searchParam.pageNo = val;
        this.getDataList();
    }
    // 变更权限
    private toggleAuth(row: any) {
        const state = row.state;
        const h = this.$createElement;
        let title = '';
        let message = null;
        if (state === 1) {
            title = '关闭权限';
            message = h('p', {}, [
                h('span', {}, '确定要关闭员工"'),
                h('span', { style: 'color: #409eff' }, `${row.mobile}（${row.name}）`),
                h('span', {}, '"的报备客户权限吗？关闭后，员工"'),
                h('span', { style: 'color: #409eff' }, `${row.mobile}（${row.name}）`),
                h('span', {}, '"负责的报备客户的负责人将被置空。'),
            ]);
        } else {
            title = '开通权限';
            message = h('p', {}, [
                h('span', {}, '确定要开通员工"'),
                h('span', { style: 'color: #409eff' }, `${row.mobile}（${row.name}）`),
                h('span', {}, '"的报备客户权限吗？'),
            ]);
        }
        this.$msgbox({
          title,
          message,
          showCancelButton: true,
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          beforeClose: (action, instance, done) => {
            if (action === 'confirm') {
                instance.confirmButtonLoading = true;
                const paramData: any = {
                    state: state === 1 ? 0 : 1,
                };
                this.$httpService
                .putData(paramData, `/apiProxy/api/frontend/customer/staff-assignment/${row.userId}/state`)
                .then((res: any) => {
                    this.$message.success(state === 1 ? '关闭权限成功' : '开通权限成功');
                    done();
                    instance.confirmButtonLoading = false;
                    this.getDataList();
                    this.count++;
                }).catch(() => {
                    instance.confirmButtonLoading = false;
                });
            } else {
                done();
            }
          },
        });
    }
}
