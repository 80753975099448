
import { Component, Vue, Prop, Watch, Emit } from "vue-property-decorator";
import ContentTitle from "@/components/ContentTitle.vue";
import EcologyExpire from "@/components/EcologyExpire/index.vue";
import { ElForm } from "element-ui/types/form";
import { debounce, verifyPhone, subDate } from "@/utils/utils";
import SelectOrder from "./SelectOrder.vue";

@Component({
    name: "ProjectAuthApply",
    components: {
        ContentTitle,
        EcologyExpire,
        SelectOrder,
    },
})
export default class ProjectAuthApply extends Vue {
    @Prop(Number) private applyType!: number; // 1为正式授权申请 2为测试授权申请
    @Prop({
        type: String,
        default: '',
    }) private orderId!: string; // 接收父组件传过来的值
    private userInfo: any = this.$store.state.userInfo;
    private contentTitle: string = '';
    private projectNameError: string = ""; // 项目名称已存在，如需为该项目申请延续、服务器变更等操作，请返回项目列表查找到该项目后点击对应的操作功能申请
    private proJectNamePlaceholder: string = '建议项目名称由“客户简称+系统项目”组成，例如：摩尔元数MES项目';
    private formData: any = {
        authType: 1, // 1：mc 2:wis3d
        customerId: '',
        contacts: '',
        mobile: '',
        address: '',
        projectName: '',
        licenseEndTime: '',
        licenseTimeForever: 0,
        goodsId: '4',
        registerUrl: '',
        userNumber: 0,
        remarks: '',
        actuallyMoney: '',
        type: null,
        time: '1',
        reportCustomerId: null,
        projectCustomerId: null,
        orderNo: '',
        associatedOrderId: '',
        productName: '',
    };
    private payMoney: any = '0.00';
    private formDataRule: any = {
        customerId: [
            { required: true, message: '请选择报备客户', trigger: 'change' },
        ],
        mobile: [
            { validator: this.validateMobile, trigger: 'blur' },
        ],
        projectName: [
            { required: true, message: '请输入项目名称', trigger: 'change' },
        ],
        time: [
            { required: true, message: '请输入项目名称', trigger: 'change' },
        ],
        registerUrl: [
            { required: true, message: '请上传注册文件', trigger: 'change' },
        ],
        actuallyMoney: [
            { required: true, message: '请输入实际项目金额', trigger: 'change' },
        ],
        remarks: [
            { required: true, message: '请输入备注说明', trigger: 'change' },
        ],
    };
    private mcImgArr = [
        require("@/assets/images/projectList/mcNormal.png"),
        require("@/assets/images/projectList/mcSel.png"),
    ];
    private mcImg: any = this.mcImgArr[1];
    private wisImgArr = [
        require("@/assets/images/projectList/wis3dNormal.png"),
        require("@/assets/images/projectList/wis3dSel.png"),
    ];
    private wisImg: any = this.wisImgArr[0];
    private customerOptions: any[] = [];
    private personInfo: any = {}; // 当前登录人信息
    private applicationInfo: any = {}; // 应该信息，单价等
    private isEcoPartner: any = false;
    private recordPersonInfo: any = {}; // 记录非生态伙伴时，数据缓存。当切换mc和wis3D的时候使用
    private selectOrderStatus: any = null;
    private foreverTime = '永久授权';
    private fileName: any = '';
    // 选择订单
    private selectOrderFlag: boolean = false;
    private pickerOptions: any = {
        disabledDate: (val: any) => {
            return val > new Date(subDate(new Date(), 1, 'Y'));
        },
    };
    @Watch("applyType", { immediate: true })
    private applyTypeWatch(val: number) {
        if (val === 1) {
            this.contentTitle = "正式项目授权申请";
            this.proJectNamePlaceholder = '建议项目名称由“客户简称+系统项目”组成，例如：摩尔元数MES项目';
            this.formData.type =  1;
        } else if (val === 2) {
            this.contentTitle = "测试项目授权申请";
            this.proJectNamePlaceholder = '建议项目名称由“客户简称+系统项目”组成，例如：摩尔元数MES测试项目';
            this.formData.type =  4;
        }
    }
    @Watch('formData.licenseTimeForever')
    private licenseTimeForeverChange(value: number) {
        if (value === 1) {
            this.formData.licenseEndTime = '';
            this.$nextTick(() => {
                const authFormRef = this.$refs.projectAuthApplyForm as ElForm;
                authFormRef.validateField('licenseEndTime');
            });
        }
    }
    @Emit('goBack')
    private backTodo(num: number): number {
        // num === 1 返回
        // num === 2 跳转授权历史页面
        return num;
    }
    @Emit('payBack')
    private payBackTodo(obj: any): any {
        // obj 支付订单内容
        return obj;
    }
    get unitPrice() {
        return this.formData.goodsId === '4'
            ? (this.payMoney * this.formData.userNumber || 0).toFixed(2) : this.payMoney;
    }
    private changeActuallyMoneyNum() {
        // 先把非数字的都替换掉，除了数字和.
        this.formData.actuallyMoney = String(this.formData.actuallyMoney).replace(/[^\d.]/g, "");
        // 必须保证第一个为不为0的数字而不是.
        this.formData.actuallyMoney = String(this.formData.actuallyMoney).replace(/^\./g, "");
        // 保证只有出现一个.而没有多个.
        this.formData.actuallyMoney = String(this.formData.actuallyMoney).replace(/\.{2,}/g, ".");
        // 保证.只出现一次，而不能出现两次以上
        this.formData.actuallyMoney =
            String(this.formData.actuallyMoney).replace(".", "$#$").replace(/\./g, "").replace("$#$", ".");
        // 只能输入两个小数
        this.formData.actuallyMoney =
            String(this.formData.actuallyMoney).replace(/^(\-)*(\d+)\.(\d\d).*$/, '$1$2.$3');
        // 以上已经过滤，此处控制的是如果没有小数点，首位不能为类似于 01、02的金额
        if (this.formData.actuallyMoney.indexOf(".") < 0 && this.formData.actuallyMoney !== "") {
            this.formData.actuallyMoney = parseFloat(this.formData.actuallyMoney);
        }
    }
    private getUserCustomerCompany() {
        this.$httpService.getData({
                notLoading: true,
            }, `/apiProxy/api/frontend/company/customer/user/customer-company`)
        .then((res: any) => {
            this.customerOptions = res;
        });
    }
    // 获得申请授权信息
    private getApplyInfo() {
        if (this.orderId === '') {
            return;
        }
        this.$httpService.getData({notLoading: true},
        `/apiProxy/api/frontend/project/grant/associated-order/${this.orderId}/apply-info`)
        .then((res: any) => {
            // 伙伴状态(0, "已过期"),(1, "待签约"), (2, "合作中"), (3, "未加入"), (4, "已终止")
            if (this.userInfo.partnerStatus !== 3 && this.userInfo.partnerStatus !== 4) {
                this.isEcoPartner = true;
                if (this.userInfo.nickname === res.companyName) {
                    this.formData.customerId = res.companyId;
                } else {
                    this.formData.customerId = res.customerId;
                }
                this.customerChange(this.formData.customerId, true);
            } else {
                this.isEcoPartner = false;
            }
            this.formData.orderNo = res.orderNo + '';
            this.formData.associatedOrderId = this.orderId;
            this.formData.productName = res.orderGoodsName;
            this.formData.userNumber = res.licenseNum;
        });
    }
    // 通过id获取到报备客户信息
    private customerChange(vId: any, infuseFlag = false) {
        if (vId) {
            const customerOwnArr = this.customerOptions.filter((item: any) => item.id === vId);
            let ownFlag = false;
            if (customerOwnArr.length > 0 && customerOwnArr[0].info === 1) {
                ownFlag = true;
            }
            this.getPersonInfo(ownFlag, vId, infuseFlag);
        }
    }
    private mounted() {
        this.$nextTick(async () => {
            this.getApplicationInfo();
            // 伙伴状态(0, "已过期"),(1, "待签约"), (2, "合作中"), (3, "未加入"), (4, "已终止")
            if (this.userInfo.partnerStatus !== 3 && this.userInfo.partnerStatus !== 4) {
                this.isEcoPartner = true;
                this.getUserCustomerCompany();
            } else {
                this.isEcoPartner = false;
                // 添加参数，防止接口响应过慢 和 getApplyInfo 造成冲突
                this.getPersonInfo(true, null, true);
            }
            await this.getApplyInfo();
        });
    }
    private getPersonInfo(ownFlag = true, projectCustomerId = null, infuseFlag = false) {
        const paramData: any = {};
        if (ownFlag) {
            paramData.type = 1;
        } else {
            paramData.type = 0;
        }
        if (projectCustomerId) {
            paramData.projectCustomerId = projectCustomerId;
        }
        this.$httpService.getData(paramData, '/apiProxy/api/frontend/company/customer/info')
        .then((rep: any) => {
            if (rep) {
                if (ownFlag) {
                    if (!this.isEcoPartner) {
                        this.customerOptions = [];
                        this.customerOptions.push({
                            id: rep.customer.customerCompanyId,
                            name: this.userInfo.nickname,
                        });
                        this.recordPersonInfo = {
                            customerOptions: this.customerOptions,
                            customerId: rep.customer.customerCompanyId,
                            reportCustomerId: rep.customer.customerId,
                            projectCustomerId: rep.customer.customerCompanyId,
                            contacts: rep.customer.customerInfoContacts,
                            mobile: rep.customer.customerInfoMobile,
                            address: rep.customer.customerInfoAddress,
                        };
                    }
                    this.formData.customerId = rep.customer.customerCompanyId;
                }
                if (projectCustomerId !== rep.customer.customerCompanyId) {
                    this.formData.reportCustomerId = rep.customer.customerId;
                } else {
                    this.formData.reportCustomerId = null;
                }
                this.formData.projectCustomerId = rep.customer.customerCompanyId;
                this.formData.contacts = rep.customer.customerInfoContacts;
                this.formData.mobile = rep.customer.customerInfoMobile;
                this.formData.address = rep.customer.customerInfoAddress;
                // 当正式申请 并且是 mc申请的时候 才重置
                if (!infuseFlag && this.applyType === 1 && this.formData.authType === 1) {
                    // 重置选择订单信息
                    this.formData.orderNo = '';
                    this.formData.productName = '';
                    this.formData.userNumber = 0;
                }
            }
        });
    }
    private getApplicationInfo() {
        this.$httpService.getData({}, '/apiProxy/api/frontend/project/grant/application-info')
        .then((rep: any) => {
            if (rep) {
                // {
                //     price: 6000 // 授权价格
                //     testUserNumber: 10 // 测试服务器 授权用户数
                //     unitPrice: 100 // 授权用户数单价
                //     wis3dFullPrice: 20000 // wis3d完整版授权价格
                // }
                this.applicationInfo = rep;
                if (this.applyType === 2) {
                    console.log('this.applyType', this.applyType);
                    this.formData.userNumber = this.applyType === 2 ? rep.testUserNumber : 0;
                }
                this.payMoney = this.applicationInfo.unitPrice.toFixed(2);
            }
        });
    }
    private authTypeChange(authType: number) {
        if (authType === this.formData.authType) {
            return;
        }
        if (authType === 1) {
            this.mcImg = this.mcImgArr[1];
            this.wisImg = this.wisImgArr[0];
            this.formData.goodsId = "4";
            this.formData.licenseTimeForever = 0;
            this.payMoney = this.applicationInfo.unitPrice.toFixed(2);
        } else if (authType === 2) {
            this.mcImg = this.mcImgArr[0];
            this.wisImg = this.wisImgArr[1];
            this.formData.goodsId = "6";
            if (this.applyType === 1) {
                this.formData.licenseTimeForever = 1;
            } else {
                this.formData.licenseTimeForever = 0;
            }
            this.payMoney = this.applicationInfo.price.toFixed(2);
            // 重置选择订单信息
            this.formData.orderNo = '';
            this.formData.productName = '';
            this.formData.userNumber = 0;
            // 清空已选中订单状态
            this.selectOrderStatus = null;
            const formRef: any = this.$refs.projectAuthApplyForm;
            formRef.clearValidate("userNumber");
        }
        if (this.isEcoPartner) {
            this.initCustomer();
        }
        this.formData.authType = authType;
        this.$nextTick(() => {
            const projectAuthApplyForm: any = this.$refs.projectAuthApplyForm;
            projectAuthApplyForm.resetFields();
            if (!this.isEcoPartner) {
                this.customerOptions = this.recordPersonInfo.customerOptions;
                this.formData.customerId = this.recordPersonInfo.customerId;
                this.formData.reportCustomerId = this.recordPersonInfo.reportCustomerId;
                this.formData.projectCustomerId = this.recordPersonInfo.projectCustomerId;
                this.formData.contacts = this.recordPersonInfo.contacts;
                this.formData.mobile = this.recordPersonInfo.mobile;
                this.formData.address = this.recordPersonInfo.address;
            }
            // 重置之后值要设置上去
            if (authType === 2) {
                this.formData.goodsId = "6";
                if (this.applyType === 1) {
                    this.formData.licenseTimeForever = 1;
                } else {
                    this.formData.licenseTimeForever = 0;
                }
            }
            this.formData.userNumber = this.applyType === 2 ? this.applicationInfo.testUserNumber : 0;
        });
    }
    // 切换的时候重置报备客户选项
    private initCustomer() {
        // this.customerOptions = [];
        this.formData.reportCustomerId = null;
        this.formData.projectCustomerId = null;
        this.formData.customerId = '';
        this.formData.contacts = '';
        this.formData.mobile = '';
        this.formData.address = '';
        this.$nextTick(() => {
            const formRef: any = this.$refs.projectAuthApplyForm;
            formRef.clearValidate(['customerId', 'mobile']);
        });
    }
    private radioChange(value: any) {
        if (value === '6') {
            this.payMoney = this.applicationInfo.price.toFixed(2);
        } else if (value === '7') {
            this.payMoney = this.applicationInfo.wis3dFullPrice.toFixed(2);
        }
    }
    private onPay(formName: any) {
        this.projectNameError = '';
        const formRef = this.$refs[formName] as ElForm;
        formRef.validate((valid: boolean) => {
            if (valid) {
                const paramData = Object.assign({}, this.formData);
                paramData.payMoney = Number(this.payMoney);
                paramData.unitPrice = Number(this.unitPrice);
                paramData.actuallyMoney = Number(paramData.actuallyMoney);
                paramData.goodsId = Number(paramData.goodsId);
                paramData.customerId = paramData.projectCustomerId;
                if (this.applyType === 1 && paramData.authType === 1) {
                    paramData.associatedOrderId = Number(paramData.associatedOrderId);
                } else {
                   delete paramData.associatedOrderId;
                }
                delete paramData.authType;
                delete paramData.time;
                delete paramData.productName;
                delete paramData.orderNo;
                this.$httpService.postData(paramData, `/apiProxy/api/frontend/project/grant/payment/1`)
                .then((rep: any) => {
                    if (rep && rep.orderIds) {
                        this.payBackTodo(rep);
                    } else {
                        this.$alert(`操作成功`, '提示', {
                            showClose: false,
                            confirmButtonText: '确定',
                            callback: (action) => {
                                this.backTodo(2);
                            },
                        });
                    }
                }).catch((err: any) => {
                    if (err.code === 1004013001) {
                        this.projectNameError = '项目名称已存在，如需为该项目申请延续、服务器变更等操作，请返回项目列表查找到该项目后点击对应的操作功能申请';
                    }
                });
            }
        });
    }
    private onSubmit(formName: any) {
        this.projectNameError = '';
        const formRef = this.$refs[formName] as ElForm;
        formRef.validate((valid: boolean) => {
            if (valid) {
                const paramData = Object.assign({}, this.formData);
                paramData.goodsId = Number(paramData.goodsId);
                paramData.customerId = paramData.projectCustomerId;
                delete paramData.authType;
                delete paramData.time;
                delete paramData.actuallyMoney;
                this.$httpService.postData(paramData, `/apiProxy/api/frontend/project/grant/test-application/${this.formData.authType}`)
                .then((rep: any) => {
                    if (rep) {
                        this.$alert(`申请成功`, '提示', {
                            showClose: false,
                            confirmButtonText: '确定',
                            callback: (action) => {
                                this.backTodo(2);
                            },
                        });
                    }
                }).catch((err: any) => {
                    if (err.code === 1004013001) {
                        this.projectNameError = '项目名称已存在，如需为该项目申请延续、服务器变更等操作，请返回项目列表查找到该项目后点击对应的操作功能申请';
                    }
                });
            }
        });
    }
    private cancelHandle() {
        this.$confirm(`是否确认取消申请，取消后所编辑的内容将不被保存`, {
            confirmButtonText: '确认',
            cancelButtonText: '取消',
        }).then(() => {
            // 确定
            this.backTodo(1);
        }).catch(() => {
            // 取消
        });
    }
    private customerHandle() {
        this.backTodo(3);
    }
    private validateMobile(rule: any, value: string, callback: any) {
        if (value !== '' && !verifyPhone(value)) {
          return callback(new Error('手机号格式不正确!'));
        }
        callback();
    }
    private verifyUserNumber(rule: any, value: any, callback: any) {
        if (value === undefined) {
            callback(new Error('请选择订单')); // 请输入申请用户数
        } else if (Number(value) <= 0) {
            callback(new Error('请选择订单')); // 请输入有效的申请用户数
        } else {
            callback();
        }
    }
    private verifyOrderNo(rule: any, value: any, callback: any) {
        if (value === '') {
            callback(new Error('请选择订单'));
        } else {
            callback();
        }
    }
    private verifyProductName(rule: any, value: any, callback: any) {
        if (value === '') {
            callback(new Error('请选择订单'));
        } else {
            callback();
        }
    }
    private getRegisterFileWay() {
        window.open('https://ntalk.morewis.com/web/article/0c2ca2d8-0638-4257-94ba-7a6da2994639');
    }
    private beforeFileUpload(file: any) {
        // 注意这里需要用Promise函数来阻止图片没有压缩好,就直接将文件上传
        return new Promise(async (resolve: any, reject: any) => {
            const isLt2M = file.size / 1024 / 1024 < 10;
            if (!isLt2M) {
                this.$message.warning('上传文件大小不能超过 10MB!');
                return reject(false);  // 注意这里需要用reject来返回错误的信息,防止图片自动上传
            }
            resolve(file); // 通过resolve将Promise函数返回成功回调,进行后面操作
        });
    }
    private httpRequestFileBusiness(data: any) {
        const param = new FormData();
        param.append('file', data.file);
        this.$httpService.postData(param, '/apiProxy/api/frontend/file/upload_image', {
            'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
        })
        .then((res: any) => {
            this.fileName = data.file.name;
            this.formData.registerUrl = res.path;
            const formRef: any = this.$refs.projectAuthApplyForm;
            formRef.validateField('registerUrl');
        });
    }
    private selectOrder() {
        if (this.formData.customerId === '') {
            this.$message.warning('请先选择报备客户');
            return;
        }
        this.selectOrderFlag = true;
    }
    private closeHandleSelectOrder(tab: any) {
        this.selectOrderFlag = false;
        if (tab.handleType === 1) {
            this.formData.orderNo = tab.orderNo + '';
            this.formData.productName = tab.productName + '';
            this.formData.userNumber = tab.userNumber + '';
            this.formData.associatedOrderId = tab.orderId + '';
            this.selectOrderStatus = tab.orderStatus;
            if (tab.orderStatus === 4) {
                this.formData.licenseTimeForever = 0;
            }
        }
    }
}
