
import { Component, Vue, Prop, Watch, Emit } from "vue-property-decorator";
import ContentTitle from "@/components/ContentTitle.vue";
import { ElForm } from "element-ui/types/form";
import { debounce, verifyPhone } from "@/utils/utils";

@Component({
    name: "AgainProjectApply",
    components: {
        ContentTitle,
    },
})
// 重新提交，只有测试申请的时候 才有
export default class AgainProjectApply extends Vue {
    @Prop({
        type: Object,
        default: {},
    }) private detailInfo!: any; // 接收父组件传过来的值
    private userInfo: any = this.$store.state.userInfo;
    private contentTitle: string = "";
    private projectNameError: string = ""; // 项目名称已存在，如需为该项目申请延续、服务器变更等操作，请返回项目列表查找到该项目后点击对应的操作功能申请
    private formData: any = {
        authType: 1, // 1：mc 2:wis3d
        customerId: '',
        customerInfoName: null,
        contacts: '',
        mobile: '',
        address: '',
        projectName: '',
        licenseEndTime: '',
        licenseTimeForever: 0,
        goodsId: null,
        registerUrl: '',
        userNumber: 0,
        remarks: '',
        type: null,
        time: '1',
        licenseType: '',
    };
    private isEcoPartner: any = false;
    private foreverTime = '永久授权';
    private fileName: any = '';
    private payMoney: any = '5000.00';
    private formDataRule: any = {
        customerId: [
            { required: true, message: '请选择报备客户', trigger: 'change' },
        ],
        mobile: [
            { validator: this.validateMobile, trigger: 'blur' },
        ],
        projectName: [
            { required: true, message: '请输入项目名称', trigger: 'change' },
        ],
        time: [
            { required: true, message: '当一个虚拟节点使用', trigger: 'change' },
        ],
        registerUrl: [
            { required: true, message: '请上传注册文件', trigger: 'change' },
        ],
        remarks: [
            { required: true, message: '请输入备注说明', trigger: 'change' },
        ],
    };
    private customerOptions: any[] = [];
    @Watch('formData.licenseTimeForever')
    private licenseTimeForeverChange(value: number) {
        if (value === 1) {
            this.formData.licenseEndTime = '';
            this.$nextTick(() => {
                const authFormRef = this.$refs.projectAuthApplyForm as ElForm;
                authFormRef.validateField('licenseEndTime');
            });
        }
    }
    @Emit('goBack')
    private backTodo(num: number): number {
        // num === 1 返回
        // num === 2 跳转授权历史页面
        return num;
    }
    private getUserCustomerCompany() {
        this.$httpService.getData({
                notLoading: true,
            }, `/apiProxy/api/frontend/company/customer/user/customer-company`)
        .then((res: any) => {
            this.customerOptions = res;
        });
    }
    // 通过id获取到报备客户信息
    private customerChange(vId: any) {
        if (vId) {
            this.getPersonInfo(false, vId);
        }
    }
    private getPersonInfo(ownFlag = true, projectCustomerId = null) {
        const paramData: any = {};
        if (!ownFlag) {
            paramData.type = 0;
        } else {
            paramData.type = 1;
        }
        if (projectCustomerId) {
            paramData.projectCustomerId = projectCustomerId;
        }
        this.$httpService.getData(paramData, '/apiProxy/api/frontend/company/customer/info')
        .then((rep: any) => {
            if (rep) {
                this.formData.projectCustomerId = rep.customer.customerCompanyId;
                this.formData.contacts = rep.customer.customerInfoContacts;
                this.formData.mobile = rep.customer.customerInfoMobile;
                this.formData.address = rep.customer.customerInfoAddress;
            }
        });
    }
    private mounted() {
        this.$nextTick(() => {
            this.getApplyInfo();
        });
    }
    private getApplyInfo() { // 3680 2
        const paramData: any = {};
        this.$httpService.getData(paramData, `/apiProxy/api/frontend/project/grant/resubmit-info/${this.detailInfo.licenseId}`)
        .then((res: any) => {
            // 返回值备注一下功能
            // res.licenseReportCustomerId 报备客户ID
            // res.projectCustomerId 报备客户匹配公司的ID
            // res.licenseUserCompanyId 公司ID
            this.formData = {
                authType: res.goodsId === 4 ? 1 : 2, // 1：mc 2:wis3d
                // 不是生态伙伴或者给自己申请时，只有 licenseUserCompanyId 而没有 licenseReportCustomerId
                customerId: res.licenseReportCustomerId || res.licenseUserCompanyId,
                customerInfoName: res.customerInfoName || '-',
                projectCustomerId: res.licenseUserCompanyId || '-',
                goodsId: res.goodsId,
                contacts: res.contacts,
                mobile: res.mobile,
                address: res.address,
                projectName: res.projectName,
                licenseEndTime: res.licenseTimeForever === 0 ? res.licenseEndTimeStr : '',
                licenseTimeForever: res.licenseTimeForever,
                registerUrl: '',
                userNumber: res.userNumber,
                remarks: '',
                time: '1',
                licenseType: res.licenseType,
            };
            if (this.formData.licenseType === 4 && this.formData.goodsId === 4) {
                this.contentTitle = '重新提交MC测试项目授权';
            } else if (this.formData.licenseType === 4
                && (this.formData.goodsId === 6 || this.formData.goodsId === 7)) {
                this.contentTitle = '重新提交Wis3D测试项目授权';
            } else if (this.formData.licenseType === 1
                && (this.formData.goodsId === 6 || this.formData.goodsId === 7)) {
                this.contentTitle = '重新提交Wis3D项目授权';
            } else if (this.formData.licenseType === 1 && this.formData.goodsId === 4) {
                this.contentTitle = '重新提交MC项目授权';
            } else {
                this.contentTitle = '重新提交';
            }
            // 伙伴状态(0, "已过期"),(1, "待签约"), (2, "合作中"), (3, "未加入"), (3, "未加入")
            if (this.userInfo.partnerStatus !== 3 && this.userInfo.partnerStatus !== 4) {
                this.isEcoPartner = true;
                this.getUserCustomerCompany();
            } else {
                this.isEcoPartner = false;
                // 不是生态伙伴的时候customerId 去自己的公司ID
                this.formData.customerId = res.licenseUserCompanyId,
                // 不是生态伙伴需要组装一下数据
                this.customerOptions = [];
                this.customerOptions.push({
                    id: res.licenseUserCompanyId,
                    name: res.customerInfoName,
                });
            }
        });
    }
    // 提交申请
    private onSubmit(formName: any) {
        this.projectNameError = '';
        const formRef = this.$refs[formName] as ElForm;
        formRef.validate((valid: boolean) => {
            if (valid) {
                const paramData: any = {};
                paramData.contacts = this.formData.contacts;
                paramData.goodsId = this.formData.goodsId;
                paramData.licenseEndTime = this.formData.licenseEndTime;
                paramData.licenseTimeForever = this.formData.licenseTimeForever;
                paramData.mobile = this.formData.mobile;
                paramData.projectId = this.formData.projectId;
                paramData.registerUrl = this.formData.registerUrl;
                paramData.remarks = this.formData.remarks;
                paramData.address = this.formData.address;
                paramData.licenseId = this.detailInfo.licenseId;
                // paramData.licenseType = this.formData.goodsId === 4 ? 2 : 4;
                // paramData.licenseType
                //     = this.formData.licenseType ? this.formData.licenseType : this.formData.goodsId === 4 ? 2 : 4;
                paramData.licenseType = this.formData.licenseType;
                paramData.projectCustomerId	= this.formData.projectCustomerId;
                paramData.licenseReportedCustomerId = this.formData.customerId;
                paramData.projectName = this.formData.projectName;
                this.$httpService.putData(paramData,
                `/apiProxy/api/frontend/project/grant/resubmit`)
                .then((rep: any) => {
                    if (rep) {
                        this.$message.success('提交成功');
                        this.backTodo(2);
                    }
                }).catch((err: any) => {
                    if (err.code === 1004013001) {
                        this.projectNameError = '项目名称已存在，如需为该项目申请延续、服务器变更等操作，请返回项目列表查找到该项目后点击对应的操作功能申请';
                    }
                });
            }
        });
    }
    private cancelHandle() {
        this.$confirm(`是否确认取消申请，取消后所编辑的内容将不被保存`, {
            confirmButtonText: '确认',
            cancelButtonText: '取消',
        }).then(() => {
            // 确定
            this.backTodo(1);
        }).catch(() => {
            // 取消
        });
    }
    private customerHandle() {
        this.backTodo(3);
    }
    private validateMobile(rule: any, value: string, callback: any) {
        if (value !== '' && !verifyPhone(value)) {
          return callback(new Error('手机号格式不正确!'));
        }
        callback();
    }
    private verifyUserNumber(rule: any, value: any, callback: any) {
        if (value === undefined) {
            callback(new Error('请输入申请用户数'));
        } else if (Number(value) <= 0) {
            callback(new Error('请输入有效的申请用户数'));
        } else {
            callback();
        }
    }
    private beforeFileUpload(file: any) {
        // 注意这里需要用Promise函数来阻止图片没有压缩好,就直接将文件上传
        return new Promise(async (resolve: any, reject: any) => {
            const isLt2M = file.size / 1024 / 1024 < 10;
            if (!isLt2M) {
                this.$message.warning('上传文件大小不能超过 10MB!');
                return reject(false);  // 注意这里需要用reject来返回错误的信息,防止图片自动上传
            }
            resolve(file); // 通过resolve将Promise函数返回成功回调,进行后面操作
        });
    }
    private httpRequestFileBusiness(data: any) {
        const param = new FormData();
        param.append('file', data.file);
        this.$httpService.postData(param, '/apiProxy/api/frontend/file/upload_image', {
            'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
        })
        .then((res: any) => {
            this.fileName = data.file.name;
            this.formData.registerUrl = res.path;
            const formRef: any = this.$refs.projectAuthApplyForm;
            formRef.validateField('registerUrl');
        });
    }
    private getRegisterFileWay() {
        window.open('https://ntalk.morewis.com/web/article/0c2ca2d8-0638-4257-94ba-7a6da2994639');
    }
}
