
import { Component, Vue, Prop, Watch, Emit } from "vue-property-decorator";
import ContentTitle from "@/components/ContentTitle.vue";
import { ElForm } from "element-ui/types/form";
import { debounce, verifyPhone } from "@/utils/utils";
import { subDate } from '@/utils/utils';

@Component({
    name: "UpdateProjectAuth",
    components: {
        ContentTitle,
    },
})
export default class UpdateProjectAuth extends Vue {
    @Prop({
        type: Object,
        default: {},
    }) private detailInfo!: any; // 接收父组件传过来的值
    private contentTitle: string = "";
    private formData: any = {
        authType: 1, // 1：mc 2:wis3d
        customerId: '',
        customerInfoName: null,
        contacts: '',
        mobile: '',
        address: '',
        projectName: '',
        licenseEndTime: '',
        licenseTimeForever: 0,
        goodsId: null,
        registerUrl: '',
        hasLicenseNumber: 0,
        userNumber: 0,
        remarks: '',
        type: null,
        time: '1',
    };
    private applyType: number = 1; // 1为正式授权申请 2为测试授权申请
    private foreverTime = '永久授权';
    private fileName: any = '';
    private payMoney: any = '5000.00';
    private formDataRule: any = {
        customerId: [
            { required: true, message: '请选择报备客户', trigger: 'change' },
        ],
        mobile: [
            { validator: this.validateMobile, trigger: 'blur' },
        ],
        projectName: [
            { required: true, message: '请输入项目名称', trigger: 'change' },
        ],
        time: [
            { required: true, message: '请输入项目名称', trigger: 'change' },
        ],
        registerUrl: [
            { required: true, message: '请上传注册文件', trigger: 'change' },
        ],
        remarks: [
            { required: true, message: '请输入备注说明', trigger: 'change' },
        ],
    };
    private customerOptions: any[] = [];
    // 授权用户数 价格 通过接口获得，延续的时候使用
    private userNumberPrice: any = 0;
    @Watch('formData.licenseTimeForever')
    private licenseTimeForeverChange(value: number) {
        if (value === 1) {
            this.formData.licenseEndTime = '';
            this.$nextTick(() => {
                const authFormRef = this.$refs.projectAuthApplyForm as ElForm;
                authFormRef.validateField('licenseEndTime');
            });
        }
    }
    @Emit('goBack')
    private backTodo(num: number): number {
        // num === 1 返回
        // num === 2 跳转授权历史页面
        return num;
    }
    @Emit('payBack')
    private payBackTodo(obj: any): any {
        // obj 支付订单内容
        return obj;
    }
    // 变更，正式项目wis3d-bi版和wis3d-完整版两个功能差不多
    get isChange() {
        const isFormalWis3d = this.detailInfo.handleType === 2 && [3, 4].includes(this.detailInfo.projectType);
        return this.detailInfo.updating || isFormalWis3d;
    }
    // 正式mc授权延续
    get isFormalMcContinue() {
        const isFormalMcContinue = this.detailInfo.handleType === 2 && this.detailInfo.projectType === 1;
        return isFormalMcContinue;
    }
    // wis3d升级
    get isUpgrade() {
        const isUpgrade = this.detailInfo.handleType === 3 && this.detailInfo.projectType === 3;
        return isUpgrade;
    }
    // 测试项目延续
    get isTestContinue() {
        const isTestContinue = this.detailInfo.handleType === 4;
        return isTestContinue;
    }
    get unitPrice() {
        return this.formData.authType === 1 ? this.payMoney * (this.formData.userNumber || 0) : this.payMoney;
    }
    get unitUserNumberPrice() {
        return this.userNumberPrice ? this.userNumberPrice.toFixed(2) : '0.00';
    }
    private mounted() {
        this.$nextTick(() => {
            this.getApplyInfo();
        });
    }
    private getApplyInfo() {
        const paramData: any = {};
        this.$httpService.getData(paramData, `/apiProxy/api/frontend/project/grant/${this.detailInfo.projectId}/info`)
        .then((res: any) => {
            this.detailInfo.projectType = res.projectType; // 为了授权历史页面使用，不需要通过上个页面传值进来
            this.formData = {
                authType: res.licenseGoodsId === 4 ? 1 : 2, // 1：mc 2:wis3d
                projectId: this.detailInfo.projectId,
                // 不是生态伙伴或者给自己申请时，只有 userCompanyId 而没有 projectReportCustomerId
                customerId: res.userCompanyId,
                customerInfoName: res.customerInfoName || '-',
                goodsId: res.licenseGoodsId,
                contacts: res.projectContacts,
                mobile: res.projectContactsMobile,
                address: res.projectCustomerAddress,
                projectName: res.projectName,
                // licenseEndTime: '',
                // licenseTimeForever:
                //     (res.licenseGoodsId !== 4 && this.detailInfo.projectType !== 2) ? 1 : 0, // 只有正式申请的wis3D才是默认永久授权,
                licenseEndTime:
                    (res.licenseEndTime && res.licenseEndTime !== '') ? subDate(new Date(res.licenseEndTime)) : '',
                licenseTimeForever: res.licenseTimeForever,
                registerUrl: '',
                hasLicenseNumber: res.projectUserNumber, // 已授权用户数
                userNumber: 0, // 添加
                remarks: '',
                time: '1',
            };
            if (this.detailInfo.updating) {
                this.contentTitle = '项目变更申请';
            } else {
                // projectType，1：正式mc授权，2：测试的mc授权和3D授权；3：正式的wis3d-bi版；4：正式的wis3d-完整版
                if (this.detailInfo.handleType === 2 && [1, 3, 4].includes(this.detailInfo.projectType)) {
                    // 变更转延续
                    this.contentTitle = '项目延续申请';
                    this.userNumberPrice = 0;
                } else if (this.detailInfo.handleType === 3 && this.detailInfo.projectType === 3) {
                    // 升级
                    this.contentTitle = '项目升级申请';
                    this.formData.goodsId = 7;
                    this.payMoney = res.price.toFixed(2);
                } else if (this.detailInfo.handleType === 4) {
                    // 测试项目的延续也使用 3
                     this.contentTitle = '项目延续申请';
                } else {
                    this.contentTitle = '项目申请';
                }
            }
            if (this.detailInfo.projectType === 2) {
                this.applyType = 2;
            } else {
                this.applyType = 1;
            }
        });
    }
    // 立即支付 1：首次申请，2：延续；3：升级
    private onPay(formName: any) {
        if (!this.formData.userNumber) {
            this.formData.userNumber = 0;
        }
        const formRef = this.$refs[formName] as ElForm;
        formRef.validate((valid: boolean) => {
            if (valid) {
                if (this.detailInfo.projectType === 1) {
                    this.onPayRequest(2);
                } else if (this.detailInfo.projectType === 3) {
                    this.onPayRequest(3);
                }
            }
        });
    }
    // 立即支付 请求
    private onPayRequest(priceType: number) {
        const paramData: any = Object.assign({}, this.formData);
        delete paramData.time;
        delete paramData.authType;
        delete paramData.hasLicenseNumber;
        delete paramData.customerInfoName;
        this.$httpService.postData(paramData,
        `/apiProxy/api/frontend/project/grant/payment/${priceType}`)
        .then((rep: any) => {
            if (rep && rep.orderIds) {
                this.payBackTodo(rep);
            } else {
                this.$alert(`操作成功`, '提示', {
                    showClose: false,
                    confirmButtonText: '确定',
                    callback: (action) => {
                        this.backTodo(2);
                    },
                });
            }
        });
    }
    // 提交议价 1：首次申请，2：延续；3：升级
    private submitPrice(formName: any) {
        if (!this.formData.userNumber) {
            this.formData.userNumber = 0;
        }
        const formRef = this.$refs[formName] as ElForm;
        formRef.validate((valid: boolean) => {
            if (valid) {
                if (this.detailInfo.projectType === 1) {
                    this.submitPriceRequest(2);
                } else if (this.detailInfo.projectType === 3) {
                    this.submitPriceRequest(3);
                }
            }
        });
    }
    // 提交议价 请求
    private submitPriceRequest(priceType: number) {
        const paramData: any = Object.assign({}, this.formData);
        delete paramData.time;
        delete paramData.authType;
        delete paramData.hasLicenseNumber;
        delete paramData.customerInfoName;
        this.$httpService.postData(paramData,
        `/apiProxy/api/frontend/project/grant/change-price/${priceType}`)
        .then((rep: any) => {
            if (rep) {
                this.$alert(`提交成功`, '提示', {
                    showClose: false,
                    confirmButtonText: '确定',
                    callback: (action) => {
                        this.backTodo(2);
                    },
                });
            }
        });
    }
    // 提交申请
    private onSubmit(formName: any) {
        const formRef = this.$refs[formName] as ElForm;
        formRef.validate((valid: boolean) => {
            if (valid) {
                const paramData: any = {};
                paramData.contacts = this.formData.contacts;
                paramData.goodsId = Number(this.formData.goodsId);
                paramData.licenseEndTime = this.formData.licenseEndTime;
                paramData.licenseTimeForever = this.formData.licenseTimeForever;
                paramData.mobile = this.formData.mobile;
                paramData.projectId = this.formData.projectId;
                paramData.registerUrl = this.formData.registerUrl;
                paramData.remarks = this.formData.remarks;
                paramData.address = this.formData.address;
                // 2：是正式项目wis3d-bi版和wis3d-完整版变更转延续操作；4：测试项目延续操作
                if (this.detailInfo.handleType === 2 || this.detailInfo.handleType === 4) {
                    paramData.licenseType = 3;
                }
                this.$httpService.putData(paramData,
                `/apiProxy/api/frontend/project/grant/${this.detailInfo.projectId}/change-server`)
                .then((rep: any) => {
                    if (rep) {
                        this.$alert(`提交成功`, '提示', {
                            showClose: false,
                            confirmButtonText: '确定',
                            callback: (action) => {
                                this.backTodo(2);
                            },
                        });
                    }
                });
            }
        });
    }
    private cancelHandle() {
        this.$confirm(`是否确认取消申请，取消后所编辑的内容将不被保存`, {
            confirmButtonText: '确认',
            cancelButtonText: '取消',
        }).then(() => {
            // 确定
            this.backTodo(1);
        }).catch(() => {
            // 取消
        });
    }
    private validateMobile(rule: any, value: string, callback: any) {
        if (value !== '' && !verifyPhone(value)) {
          return callback(new Error('手机号格式不正确!'));
        }
        callback();
    }
    private verifyUserNumber(rule: any, value: any, callback: any) {
        if (value === undefined) {
            callback(new Error('请输入申请用户数'));
        } else if (Number(value) <= 0) {
            callback(new Error('请输入有效的申请用户数'));
        } else {
            callback();
        }
    }
    private beforeFileUpload(file: any) {
        // 注意这里需要用Promise函数来阻止图片没有压缩好,就直接将文件上传
        return new Promise(async (resolve: any, reject: any) => {
            const isLt2M = file.size / 1024 / 1024 < 10;
            if (!isLt2M) {
                this.$message.warning('上传文件大小不能超过 10MB!');
                return reject(false);  // 注意这里需要用reject来返回错误的信息,防止图片自动上传
            }
            resolve(file); // 通过resolve将Promise函数返回成功回调,进行后面操作
        });
    }
    private httpRequestFileBusiness(data: any) {
        const param = new FormData();
        param.append('file', data.file);
        this.$httpService.postData(param, '/apiProxy/api/frontend/file/upload_image', {
            'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
        })
        .then((res: any) => {
            this.fileName = data.file.name;
            this.formData.registerUrl = res.path;
            const formRef: any = this.$refs.projectAuthApplyForm;
            formRef.validateField('registerUrl');
        });
    }
    private getRegisterFileWay() {
        window.open('https://ntalk.morewis.com/web/article/0c2ca2d8-0638-4257-94ba-7a6da2994639');
    }
    private handleChangeUserNumber() {
        if (this.formData.userNumber === undefined) {
            this.formData.userNumber = 0;
        }
        if (this.formData.userNumber <= 0) {
            this.userNumberPrice = 0;
        } else {
            debounce(() => {
                this.getPayPrice();
            }, 1000)();
        }
    }
    private getPayPrice() {
        const paramData: any = {};
        paramData.buyNumber = this.formData.userNumber;
        if (this.formData.userNumber <= 0) {
            return;
        }
        this.$httpService.getData(paramData,
        `/apiProxy/api/frontend/project/grant/license/price`)
        .then((res: any) => {
            this.userNumberPrice = res;
        });
    }
}
