
import { Component, Vue, Prop, Watch, Emit  } from 'vue-property-decorator';
import { moneyFormat } from '@/utils/utils';
import { assembleOrderItemBuyout } from '@/utils/assemble';

@Component({
    name: "SelectOrder",
})
export default class SelectOrder extends Vue {
    private baseUrl = process.env.NODE_ENV === 'development' ? '/apiProxy' : '';
    private moneyFormat: any = moneyFormat;
    private pageNo: number = 1;
    private pageSize: number = 10;
    private total: number = 0;
    private orderDataList: any = [];
    private checkboxDis: boolean = false;
    private title: string = '关联订单';
    @Prop({
        type: String,
        default: '',
    }) private customerCompanyId!: string; // 接收父组件传过来的值
    @Prop({
        type: String,
        default: '',
    }) private orderNo!: string; // 接收父组件传过来的值
    @Prop({
        type: Boolean,
        default: false,
    }) private showFlag!: boolean; // 接收父组件传过来的值
    private showDialog: boolean = false; // 弹窗显示与关闭
    @Watch('showFlag') // 监听父组件传过来的值
    private changeFlagFun(value: boolean) {
        if (value) {
            this.getOrderList();
            if (this.orderNo !== '') {
                this.title = `关联订单（已关联订单：${this.orderNo}）` ;
            } else {
                this.title = `关联订单`;
            }
        }
    }
    // 给父组件传值
    @Emit('closeHandle')
    private closeTodo(tab: any): any {
        this.showDialog = false;
        return tab;
    }
    // 右上角的关闭按钮
    private beforeClose() {
        this.closeTodo({
            handleType: 0,
        }); // 给父组件传值
    }
    private handleSizeChange(val: number) {
        console.log(`每页 ${val} 条`);
        this.pageNo = 1;
        this.pageSize = val;
        this.getOrderList();
    }
    private handleCurrentChange(val: number) {
        console.log(`当前页: ${val}`);
        this.pageNo = val;
        this.getOrderList();
    }
    // 获取订单列表
    private getOrderList() {
        const formData: any = {
            pageNo: this.pageNo,
            pageSize: this.pageSize,
            customerCompanyId: this.customerCompanyId,
        };
        this.$httpService.getData(formData, '/apiProxy/api/frontend/project/grant/associated-order/offline')
        .then((res: any) => {
            if (res && res.total > 0) {
                this.showDialog = true;
                // this.orderDataList = res.list;
                this.orderDataList = [];
                res.list.filter((item: any) => {
                    item.isSelect = this.orderNo === item.orderNo ? true : false;
                    // 买断产品和授权用户数 上下显示
                    const detailListCopy: any = assembleOrderItemBuyout(item.orderGoodsDetailList);
                    item.orderGoodsDetailList = [].concat(detailListCopy);
                    this.orderDataList.push(item);
                });
                this.total = res.total;
            } else {
                this.beforeClose();
                this.orderDataList = [];
                this.total = 0;
            }
        }).catch((err: any) => {
            this.beforeClose();
        });
    }
    private checkboxHandle(item: any) {
        if (Number(this.orderNo) !== item.orderNo) {
            this.$confirm(`确定要选择订单“${item.orderNo}”吗？`, '订单确认', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
            }).then(() => {
                const grantTab = item.orderGoodsDetailList.find((el: any) => el.goodsType === 11);
                this.closeTodo({
                    handleType: 1,
                    orderId: item.id,
                    orderNo: item.orderNo,
                    orderStatus: item.orderStatus,
                    productName: item.orderTitle,
                    userNumber: grantTab.buyNumber || 0,
                }); // 给父组件传值
            }).catch(() => {
                item.isSelect = false;
            });
        }
    }
}
