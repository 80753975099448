
import { ElForm } from 'node_modules/element-ui/types/form';
import { Component, Vue, Prop, Watch, Emit } from 'vue-property-decorator';
import { verifyPhone } from '@/utils/utils';

@Component({
  name: 'FollowDialog',
})
export default class FollowDialog extends Vue {
    private followData: any = {
        contactName: '',
        contactPhone: '',
        followTime: '',
        followContent: '',
    };
    private followRules: any = {
        followTime: [
            { required: true, message: '请选择跟进日期', trigger: 'change' },
        ],
        followContent: [
            { required: true, message: '请输入跟进内容', trigger: 'change' },
        ],
    };
    private pickerOptions: any = {
        disabledDate: (val: any) => {
            return val > new Date();
        },
    };
    @Prop({
        type: Boolean,
        default: false,
    }) private EditFlag!: boolean; // 接收父组件传过来的值
    @Prop({
        type: Number,
        default: 0,
    }) private customerId !: number;
    private showDialog: boolean = false; // 弹窗显示与关闭
    @Watch('EditFlag') // 监听父组件传过来的值
    private EditFlagFun(value: boolean) {
        this.showDialog = value;
    }
    // 右上角的关闭按钮
    private beforeClose() {
        this.closeHandleTodo(1); // 给父组件传值
    }
    // 给父组件传值
    @Emit('closeHandle')
    private closeHandleTodo(num: number): number {
        this.showDialog = false;
        this.followData = {
            contactName: '',
            contactPhone: '',
            followTime: '',
            followContent: '',
        };
        this.resetForm('followForm');
        return num;
    }
    private resetForm(formName: any) {
        const elForm = this.$refs[formName]! as ElForm;
        elForm.resetFields();
    }
    private submitFollow(formName: any) {
        if (this.followData.contactPhone !== '' && !verifyPhone(this.followData.contactPhone)) {
            this.$message.warning('手机号格式不正确!');
            return;
        }
        const elForm = this.$refs[formName]! as ElForm;
        elForm.validate((valid: boolean) => {
            if (valid) {
                this.followRequest();
            }
        });
    }
    private followRequest() {
        let paramData: any = {};
        paramData = this.followData;
        paramData.customerId = this.customerId;
        this.$httpService.postData(paramData, '/apiProxy/api/frontend/company/customer/follow-record')
        .then((res: any) => {
            this.$message.success('提交成功');
            this.closeHandleTodo(2); // 给父组件传值
        });
    }
}
